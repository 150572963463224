<template>
  <el-row>
    <el-form
        ref="generateReportForm"
        :model="addForm"
        :rules="formRules"
        status-icon
        label-position="left"
        label-width="135px"
        style="max-width: 570px">

      <el-form-item label="Selskap" prop="department">
        <el-select v-model="addForm.department" placeholder="velg et selskap" style="max-width: 419px; width: 219px">
          <el-option v-if="loading.departments" v-loading="loading.departments" value="" disabled/>
          <!--<el-option v-for="proj in projStore.projects" :label="proj.name" :value="proj.id" :key="proj.id" />-->
          <el-option-group>
            <el-option v-for="group in departmentStore.departments" :value="group.id" :key="group.id"
                       :label="group.name"/>
          </el-option-group>
        </el-select>
      </el-form-item>

      <el-form-item label="Prosjekt" prop="project">
        <el-select v-model="addForm.project" placeholder="velg et selskap" style="max-width: 219px">
          <el-option v-if="loading.departments" v-loading="loading.departments" value="" disabled/>
          <!--<el-option v-for="proj in projStore.projects" :label="proj.name" :value="proj.id" :key="proj.id" />-->
          <el-option-group>
            <el-option v-for="group in projectStore.getProjectsForMyDepartmentReport(addForm.department)"
                       :value="group.id"
                       :key="group.id"
                       :label="group.name"/>
          </el-option-group>
        </el-select>
      </el-form-item>

      <el-button type="primary" size="large" @click="generateReport" v-loading="loading.report">Lag rapport</el-button>
    </el-form>

  </el-row>
</template>

<script setup>
import {inject, onMounted, ref, watch} from "vue";
import {useDepartmentStore} from "@/store/departmentStore";
import {HTTP, authHeader} from "@/api";
import {useProjectStore} from "@/store/projectStore";

// eslint-disable-next-line no-undef
const props = defineProps({
  start_range: Number,
  end_range: Number,
})

const departmentStore = useDepartmentStore();
const projectStore = useProjectStore();

const generateReportForm = ref(null);

const addForm = ref({
  department: "",
  project: "",
})
const formRules = {
  department: [{required: true, message: 'Velg et selskap.', trigger: 'change'}],
  project: [{required: true, message: 'Velg et prosjekt.', trigger: 'change'}],
}

const loading = inject('loading')

const generateReport = async () => {
  const valid = await generateReportForm.value.validate();

  if (valid) {
    try {
      let requestHeaders = authHeader();
      requestHeaders.responseType = "blob";
      console.log(requestHeaders);
      const response = await HTTP.get(`/hours/project/${addForm.value.project}/report?range_start=${props.start_range}&range_end=${props.end_range}&only_for_current_user=${false}`, requestHeaders);
      console.log(response);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      console.log(url)
      const link = document.createElement('a');
      link.href = url;

      // Try to extract filename from the Content-Disposition header
      const contentDisposition = response.headers['content-disposition'];
      console.log("Content-Disposition: ", contentDisposition);
      let filename = 'default-filename.xlsx';
      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(/filename="?(.+)"?/);
        if (filenameMatch.length === 2)
          filename = filenameMatch[1];
      }

      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (e) {
      console.error(e);
    }
  }
}

watch(() => addForm.value.department, (newDepartment) => {
  addForm.value.project = projectStore.getProjectsForMyDepartmentReport(newDepartment)[0];
});

onMounted(async () => {
  await departmentStore.getAllMyDepartments();
  await projectStore.getMyProjects();
  if (departmentStore.departments.length > 0) {
    addForm.value.department = departmentStore.departments[0].id;
    loading.departments = false;
  }
})


</script>

<style scoped>

</style>