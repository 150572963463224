<template>
   <div id="login-div" v-loading="loading.login">

      <!-- Display correct logo depending on dark mode -->
      <img v-if="appStore.darkMode" class="login-logo" src="@/assets/RetramsLogo.png">
      <img v-else class="login-logo" src="@/assets/RetramsLogoDark.png">
      <el-divider />

      <!-- The login form -->
      <el-form ref="loginForm" :model="loginForm" :rules="formRules" status-icon @submit.prevent v-on:keyup.enter="submitFormClicked">
         <el-form-item prop="username">
            <el-input v-model="loginForm.username" placeholder="E-postadresse" clearable />
         </el-form-item>

         <el-form-item prop="password" style="margin-bottom: 11px">
            <el-input v-model="loginForm.password" placeholder="Passord" type="password" clearable show-password />
         </el-form-item>

         <!-- TODO - forgot password-button does nothing for the moment -->
         <el-link id="forgot-pw" href="#" :underline="false">Glemt passord?</el-link>

         <el-form-item>
            <el-button type="primary" round @click="submitFormClicked">Logg inn</el-button>
         </el-form-item>
      </el-form>
   </div>
</template>

<script>
import { useAppStore } from "@/store/appStore";
import { useAuthStore } from "@/store/authStore";
import { Constants } from "@/constants";


export default {
   name: "LoginView",
   setup() {
      const appStore = useAppStore()
      const authStore = useAuthStore()
      return { appStore, authStore }
   },
   data() {
      return {
         Constants: Constants,
         loginForm: {username: '', password: ''},
         loading: {login: false},
         formRules: {  // login-form validation rules
            username: [{ required: true, type: 'email', message: 'Skriv inn riktig e-post.', trigger: 'blur' }],
            password: [
               { required: true, message: 'Skriv inn passord.', trigger: 'blur' },
               { min: Constants.PW_MIN_LENGTH, message: 'Passordet må inneholde minst ' + Constants.PW_MIN_LENGTH + ' tegn.', trigger: 'blur' }]
         },
      }
   },
   computed: {
      loginData() {
         const form = new FormData()
         form.set('username', this.loginForm.username)
         form.set('password', this.loginForm.password)
         return form
      }
   },
   methods: {
      async submitFormClicked() {
         await this.$refs.loginForm.validate((valid) => {
            if (valid) {
               this.login()
            }
         })
      },
      async login() {
         this.loading.login = true
         const success = await this.authStore.login(this.loginData)
         if (success) {
            return this.$router.push("/")
         }
         this.loading.login = false
      }
   },
}
</script>

<style scoped>
#login-div {
   width: 400px;
   height: 310px;
   position: absolute;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   margin: auto;
   display: grid;
   justify-content: center;
   box-shadow: 0px 0px 5px gray;
   border-radius: 12px;

}
.login-logo {
   height: 55px;
   width: 200px;
   margin: 10px auto 0;
}
#login-div .el-form {
   width: 360px;
}

#login-div .el-input {
   height: 36px;
}

#login-div .el-button {
   width: 100%;
   height: 36px;
   margin-top: 20px;
}
.el-divider {
   margin-top: -15px;
   margin-bottom: 0px;
}
#forgot-pw {
   font-size: 13px;
}
</style>