import {defineStore} from "pinia";
import {useAuthStore} from "@/store/authStore";
import {ElMessage} from "element-plus";
import {HTTP, authHeader} from "@/api";


export const useDepartmentStore = defineStore("departments", {
    state: () => ({
        authStore: useAuthStore(),
        departments: [],
    }),
    getters: {},
    actions: {
        async getAllDepartments() {
            try {
                const response = await HTTP.get('departments/', authHeader())
                this.departments = response.data
                console.log(this.departments)

            } catch (error) {
                console.log(error)
                ElMessage.error('Feil ved innlasting av avdelinger ')
            }
        },
        async getAllManagerDepartments() {
            try {
                const response = await HTTP.get('departments/management/' + this.authStore.email, authHeader())
                this.departments = response.data
                console.log(this.departments)
            } catch (error) {
                console.log(error)
                ElMessage.error('Feil ved innlasting av avdelinger')
            }
        },
        async getAllMyDepartments() {
            try {
                const response = await HTTP.get('departments/employee/' + this.authStore.email, authHeader())
                this.departments = response.data
            } catch (error) {
                console.log(error)
                ElMessage.error('Feil ved innlasting av avdelinger')
            }
        },
        getDepartment(id) {
            return this.departments.filter(department => department.id === id)[0];
        },
    }
})