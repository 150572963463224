<template>

  <!-- Table controls -->
  <el-row justify="space-between" class="table-controls">

    <!-- View mode picker (calender/list) -->
    <el-radio-group v-model="viewModeRadio">
      <!--      <el-radio-button label="cal">Kalender</el-radio-button>-->
      <el-radio-button label="list">Liste</el-radio-button>
    </el-radio-group>

    <!-- Date range picker -->
    <el-row>
      <el-button @click="tableRangeArrowsClicked(-1)" :icon="arrowLeft"/>
      <el-date-picker
          ref="rangePickerRef"
          v-model="tableRange"
          @change="tableRangeChanged"
          @visible-change="rangePickerClosed"
          :type="tableRangeType"
          :format="tableRangeFormat"
          style="width: 150px"/>
      <el-button @click="tableRangeArrowsClicked(1)" :icon="arrowRight"/>
    </el-row>

    <!-- Date range type picker (day/week/month) -->
    <el-radio-group v-model="tableRangeType" @change="tableRangeTypeChanged">
      <el-radio-button label="date">Dag</el-radio-button>
      <el-radio-button label="week">Uke</el-radio-button>
      <el-radio-button label="month">Måned</el-radio-button>
    </el-radio-group>
  </el-row>

  <!-- Update hour form when pressing on 'Update hour'-button -->
  <el-dialog v-model="editRowShow" title="Oppdater time" width="650px">
    <EditHour :hour="editHour" ref="hourUpdateRef"/>
    <template #footer>
      <el-button @click="editRowShow = false">Avbryt</el-button>
      <el-button type="primary" @click="updateHour">Oppdater</el-button>
    </template>
  </el-dialog>
  <!-- The table -->
  <el-table
      ref="hoursTableRef"
      :data="hourStore.hours"
      v-loading="loading.table"
      border
      max-height="68vh"
      :summary-method="tableSummary"
      :row-class-name="tableRowClassName"
      @row-click="rowClicked"
      style="min-width: 550px"
      show-summary>  <!-- 710px for no double-lines in header -->

    <!-- The expandable row -->
    <el-table-column type="expand" width="55px">
      <template #default="scope">
        <div class="expandable-row-div">

          <!-- Title and delete-button -->
          <el-row justify="space-between" align="middle">
            <h3>Flere detaljer</h3>
            <div>
              <el-button v-if="scope.row.loggedElapsedMs < Constants.HOUR_DELETE_LIMIT && (authStore.isAdmin || authStore.isManager)" type="primary" :icon="editIcon"
                         @click="editRowClicked(scope.row)">Endre
              </el-button>
              <el-popconfirm
                  v-if="scope.row.loggedElapsedMs < Constants.HOUR_DELETE_LIMIT && (authStore.isAdmin || authStore.isManager)"
                  title="Er du sikker på at du vil slette timen?"
                  width="270px"
                  cancel-button-text="Avbryt"
                  hide-icon
                  @confirm="deleteHour(scope.row)">
                <template #reference>
                  <el-button type="danger" :icon="deleteIcon">Slett time</el-button>
                </template>
              </el-popconfirm>
            </div>
          </el-row>

          <!-- Extra details form. Using form for easy layout/styling -->
          <el-form id="expandable-row-form" label-width="auto" label-position="left">
            <el-form-item label="Starttid">{{ expandableRowTimeFormatter(scope.row.start_time) }}</el-form-item>
            <el-form-item label="Sluttid">{{ expandableRowTimeFormatter(scope.row.end_time) }}</el-form-item>
            <el-form-item label="Selskap">{{ scope.row.project.department.name }}</el-form-item>
            <el-form-item label="Beskrivelse">{{ scope.row.description || '-' }}</el-form-item>
            <el-form-item label="Lagt til">{{ expandableRowTimeFormatter(scope.row.logged_time, true) }}</el-form-item>
            <!--            <el-form-item label="Lokasjon">{{ "TODO" }}</el-form-item>-->
          </el-form>
        </div>
      </template>
    </el-table-column>

    <!-- The table columns -->
    <el-table-column prop="project.name" label="Prosjekt" :min-width="20"/>
    <el-table-column prop="startDay" label="Dag" :min-width="15" :formatter="tableRowFormatter"/>
    <el-table-column prop="startDate" label="Dato" :min-width="15" :formatter="tableRowFormatter"/>
    <el-table-column prop="startTime" label="Starttid" :min-width="10" :formatter="tableRowFormatter"/>
    <el-table-column prop="endTime" label="Sluttid" :min-width="10" :formatter="tableRowFormatter"/>
    <el-table-column prop="duration" label="Varighet" :min-width="10" :formatter="tableRowFormatter"/>
  </el-table>

</template>

<script>
import {Constants} from "@/constants";
import {dayjs} from "element-plus";
import {useHourStore} from "@/store/hourStore";
import {ArrowLeft, ArrowRight, Delete, Edit} from '@element-plus/icons-vue'
import {shallowRef} from "vue";
import EditHour from "@/components/DashboardView/DashboardViewMain/MyHours/EditHour.vue";
import {useAuthStore} from "@/store/authStore";

export default {
  name: "HoursTable",
  components: {
    EditHour,
  },
  setup() {
    const hourStore = useHourStore()
    const authStore = useAuthStore()
    return {hourStore, authStore}
  },
  inject: ['loading'],
  data() {
    return {
      Constants: Constants,
      hoursMutex: false,
      recentHoursTimeouts: [],
      viewModeRadio: 'list',
      tableRange: new Date(),  // tableRange: The selected range to view hours, for example 'week 20 2023', or 'May 2023'
      tableRangeType: 'month',  // tableRangeType: day/week/month
      tableRangeTypePrevious: 'month',
      rangePickerSelectedNew: true,
      arrowLeft: shallowRef(ArrowLeft),
      arrowRight: shallowRef(ArrowRight),
      deleteIcon: shallowRef(Delete),
      editIcon: shallowRef(Edit),
      editRowShow: false,
      editHour: {},
    }
  },
  watch: {
    async hourStoreHours() {
      // Using a "mutex" because we only want to watch the variable once, otherwise
      // it will be circular because we modify it in again the function
      if (this.hoursMutex) return
      this.hoursMutex = true

      const currentDate = new Date()
      this.hourStore.hours.forEach((hour) => {
        const loggedLocal = this.UTCISOToLocalDate(hour['logged_time'])
        const elapsedMs = currentDate.valueOf() - loggedLocal.valueOf()
        hour['loggedElapsedMs'] = elapsedMs
        // Every hour that is less than HOUR_DELETE_LIMIT minutes old, will have a timeout fire when the Hour becomes
        // HOUR_DELETE_LIMIT minutes old. This is to update the table row with correct color, and without the delete-button
        if (elapsedMs < this.Constants.HOUR_DELETE_LIMIT) {
          const timeoutDelay = this.Constants.HOUR_DELETE_LIMIT - elapsedMs + 100
          const timeout = setTimeout(this.refreshRecentHour, timeoutDelay, hour)
          this.recentHoursTimeouts.push(timeout)
        }
      })
      this.hoursMutex = false
      this.loading.table = false
    },
    // Keeping track of the previous tableRangeType. Sometimes a user changes the tableRangeType
    // without choosing a new range. That would cause a bug, so we need to revert it.
    tableRangeType(newValue, oldValue) {
      if (this.rangePickerSelectedNew) {
        this.tableRangeTypePrevious = oldValue
      }
    },
  },
  computed: {
    hourStoreHours() {
      return this.hourStore.hours
    },
    // The text that will be displayed in the tableRange-picker
    tableRangeFormat() {
      switch (this.tableRangeType) {
        case 'week':
          return '[Uke] ww YYYY'
        case 'month':
          return 'MMM YYYY'
        default:
          return 'DD. MMM YYYY'
      }
    },
  },
  methods: {
    async loadTable() {
      this.loading.table = true
      this.clearRecentHourTimeouts()
      const [start, end] = this.calculateRange()
      await this.hourStore.getMyHours(start, end)
    },
    // Calculates the range (start and end) of selected tableRange
    calculateRange() {
      let day = null, diff = null, start = null, end = null

      switch (this.tableRangeType) {
        case 'date':
          start = new Date(this.tableRange)
          end = new Date(start)
          end.setDate(start.getDate() + 1)
          break
        case 'week':  // Find date of monday in selected week + next week
          day = this.tableRange.getDay()
          diff = this.tableRange.getDate() - day + (day === 0 ? -6 : 1)
          start = new Date(this.tableRange)
          start.setDate(diff)
          end = new Date(start)
          end.setDate(start.getDate() + 7)
          break
        case 'month':  // Find first date in selected month + next month
          start = new Date(this.tableRange.getFullYear(), this.tableRange.getMonth(), 1)
          end = new Date(this.tableRange.getFullYear(), this.tableRange.getMonth() + 1, 1)
          break
      }
      return [start.valueOf(), end.valueOf()]
    },
    // When tableRange is changed, refresh the table with Hour's that are in the new range
    async tableRangeChanged() {
      if (this.tableRange == null) {
        this.tableRange = new Date()
        this.tableRange.setHours(0, 0, 0, 0)
      }

      this.rangePickerSelectedNew = true
      await this.loadTable()
    },
    tableRangeTypeChanged() {
      this.$refs.rangePickerRef.handleOpen()  // Open the tableRange-picker
      this.rangePickerSelectedNew = false
    },
    // If changing tableRangeType without changing tableRange, revert the tableRangeType to previous value
    rangePickerClosed(visible) {
      if (!visible && !this.rangePickerSelectedNew) {
        this.tableRangeType = this.tableRangeTypePrevious
      }
    },
    // Gets called when user clicks on the arrows next to the tableRange-picker.
    // Increases or decreases the selected tableRange by a day/week/month depending on the tableRangeType
    tableRangeArrowsClicked(sign) {  // sign is 1 if '>' is clicked, and -1 if '<' is clicked
      let newTimestamp = null
      switch (this.tableRangeType) {
        case 'date':
          newTimestamp = this.tableRange.setDate(this.tableRange.getDate() + (1 * sign))
          break
        case 'week':
          newTimestamp = this.tableRange.setDate(this.tableRange.getDate() + (7 * sign))
          break
        case 'month':
          newTimestamp = this.tableRange.setMonth(this.tableRange.getMonth() + (1 * sign))
          break
      }
      this.tableRange = new Date(newTimestamp)
      // TODO prevent user to spam arrows
      this.tableRangeChanged()
    },
    // Returns a Date-object converted to the user's local timezone from a UTC date that is in ISO-format
    UTCISOToLocalDate(UTCString) {
      const DateUTC = new Date(UTCString)
      // const DateLocal = new Date(DateUTC - (DateUTC.getTimezoneOffset() * 60000))
      return new Date(DateUTC.toISOString())
    },
    // Table cell formatter for the cells that display day, date, start/end time, and duration
    tableRowFormatter(row, column) {
      switch (column.property) {
        case 'startDay': {
          const localDate = this.UTCISOToLocalDate(row['start_time'])
          return dayjs.weekdays()[localDate.getDay()]
        }
        case 'startDate': {
          const localDate = this.UTCISOToLocalDate(row['start_time'])
          return localDate.getDate() + '. ' + dayjs.monthsShort()[localDate.getMonth()] + ' ' + localDate.getFullYear()
        }
        case 'startTime': {
          const localDate = this.UTCISOToLocalDate(row['start_time'])
          return localDate.toTimeString().substring(0, 5)
        }
        case 'endTime': {
          const localDate = this.UTCISOToLocalDate(row['end_time'])
          return localDate.toTimeString().substring(0, 5)
        }
        case 'duration': {
          const localDate1 = this.UTCISOToLocalDate(row['start_time'])
          const localDate2 = this.UTCISOToLocalDate(row['end_time'])
          let timeDelta = (localDate2.valueOf() - localDate1.valueOf()) / 1000
          const lunchStart = this.UTCISOToLocalDate(row['start_time']);
          lunchStart.setHours(12, 0, 0);
          const lunchEnd = this.UTCISOToLocalDate(row['end_time']);
          lunchEnd.setHours(12, 30, 0);
          if (timeDelta >= 19800 && !row['project'].has_paid_lunch) {
            timeDelta = timeDelta - 1800
          } else if (localDate1 <= lunchStart && localDate2 >= lunchEnd && !row['project'].has_paid_lunch) {
            timeDelta -= 1800
          }
          const timeDeltaHours = Math.floor(timeDelta / 3600)
          const timeDeltaMinutes = Math.ceil(timeDelta / 60) % 60
          // If project.paidLunch is false, we need to remove 30minutes if the duration is more than 5.5 hours
          return timeDeltaHours + 't ' + timeDeltaMinutes + 'm'
        }
        default: {
          return "-"
        }
      }
    },
    // Text formatter for a ISO date. Used in expandable row for start, end and logged time
    expandableRowTimeFormatter(timeString, withSeconds) {
      const localDate = this.UTCISOToLocalDate(timeString)
      const day = dayjs.weekdays()[localDate.getDay()]
      const date = localDate.getDate() + '. ' + dayjs.monthsShort()[localDate.getMonth()] + ' ' + localDate.getFullYear()
      const time = withSeconds ? localDate.toTimeString().substring(0, 8) : localDate.toTimeString().substring(0, 5)
      return day + ", " + date + " kl. " + time
    },
    // Timeout will fire when hour becomes HOUR_DELETE_LIMIT minutes old to update the elapsed_ms. This will
    // cause the table row to be re-rendered with correct color, and without the delete-button
    refreshRecentHour(hour) {
      const currentDate = new Date()
      const loggedLocal = this.UTCISOToLocalDate(hour['logged_time'])
      hour['loggedElapsedMs'] = currentDate.valueOf() - loggedLocal.valueOf()
    },
    // If the user for example changes tableRange or leaves the page, we need to clear all the Timeout's, so they don't fire.
    clearRecentHourTimeouts() {
      this.recentHoursTimeouts.forEach((timeout) => {
        clearTimeout(timeout)
      })
      this.recentHoursTimeouts = []
    },
    // Gets called when a table row is clicked
    rowClicked(row) {
      this.$refs.hoursTableRef.toggleRowExpansion(row)
    },
    async deleteHour(hour) {
      this.loading.table = true
      await this.hourStore.deleteHour(hour)
      await this.loadTable()
    },
    editRowClicked(row) {
      this.editHour = {
        id: row.id,
        startDate: row.start_time,
        endDate: row.end_time,
        project: row.proj_id,
        description: row.description,
      };
      this.editRowShow = true;

    },
    async updateHour() {
      const validated = await this.$refs.hourUpdateRef.submitFormClicked()
      this.editRowShow = !validated

    }
    ,
    // Calculate the summary-row of the table
    tableSummary({data}) {
      const sums = []
      sums[0] = "Sum"
      const lastColumnIndex = 6  // Update when changing amount of table columns
      let timeDeltaTotal = 0
      if (!data) return sums

      data.forEach((row) => {
        const localDate1 = this.UTCISOToLocalDate(row['start_time'])
        const localDate2 = this.UTCISOToLocalDate(row['end_time'])
        let timeDelta = (localDate2.valueOf() - localDate1.valueOf()) / 1000
        const lunchStart = this.UTCISOToLocalDate(row['start_time']);
        lunchStart.setHours(12, 0, 0);
        const lunchEnd = this.UTCISOToLocalDate(row['end_time']);
        lunchEnd.setHours(12, 30, 0);
        if (timeDelta >= 19800 && !row['project'].has_paid_lunch) {
          timeDelta = timeDelta - 1800
        } else if (localDate1 <= lunchStart && localDate2 >= lunchEnd && !row['project'].has_paid_lunch) {
          timeDelta -= 1800
        }
        timeDeltaTotal += timeDelta
      })

      const timeDeltaHours = Math.floor(timeDeltaTotal / 3600)
      const timeDeltaMinutes = Math.ceil(timeDeltaTotal / 60) % 60
      sums[lastColumnIndex] = timeDeltaHours + 't ' + timeDeltaMinutes + 'm'
      return sums
    },
    // Sets the class name for the table row.
    // Doing it manually because of bug in Element Plus if combining striped table and custom colors
    tableRowClassName({row, rowIndex}) {
      if (row.loggedElapsedMs < this.Constants.HOUR_DELETE_LIMIT) {
        return 'newly-added-row'
      }
      if (rowIndex % 2 === 0) {
        return 'striped-row'
      }
      return ''
    },
  },
  async mounted() {
    this.tableRange.setHours(0, 0, 0, 0)
    await this.loadTable()
  },
  beforeUnmount() {
    this.clearRecentHourTimeouts()
  }

}
</script>

<style scoped>
#expandable-row-form .el-form-item {
  margin-bottom: 5px;
}
</style>
<style>
.expandable-row-div {
  width: 88%;
  float: right;
  margin-top: 5px;
  margin-bottom: 20px;
  margin-right: 15px
}

.el-table .newly-added-row {
  --el-table-tr-bg-color: var(--el-color-success-light-9);
}

.el-table .striped-row {
  --el-table-tr-bg-color: var(--el-fill-color-lighter)
}
</style>