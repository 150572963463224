<!-- Component for managing employees inside a department -->
<template>
   <div v-loading="loading.all">

      <!-- Title and table controls -->
      <h3 style="text-align: start; margin-top: 20px">Ansatte</h3>
      <el-row justify="space-between">
         <el-form ref="empPosForm" :model="empPosForm" :rules="empPosFormRules" inline>
            <el-form-item prop="email" required style="margin-bottom: 15px; margin-right: 10px">
               <el-select v-model="empPosForm.email" clearable filterable placeholder="Velg ansatt">
                  <el-option v-for="emp in filteredEmployees" :key="emp.email" :label="emp.name" :value="emp.email" style="height: 100%">
                     <p style="margin-top: 0; margin-bottom: -17px">{{ emp.name }}</p>
                     <p style="margin-top: 0; margin-bottom: 0; font-size: 11px; color: gray">{{ emp.email }}</p>
                  </el-option>
               </el-select>
            </el-form-item>
            <el-button :icon="plusIcon" @click="addEmpPosClicked" style="margin-bottom: 15px">Legg til</el-button>
         </el-form>

         <div>
            <el-button :icon="editIcon" :disabled="!selectedRow" @click="toggleEmpPosManagerClicked">{{ toggleManagerText }}</el-button>
            <el-button :icon="deleteIcon" :disabled="!selectedRow" type="danger" @click="deleteEmpPosClicked">Fjern</el-button>
         </div>
      </el-row>

      <!-- Table with all employees for the department -->
      <el-table
          ref="empPosTableRef"
          border stripe
          :data="empPositionsStore.employeePositions[department.id]"
          highlight-current-row
          @row-click="rowClicked">

         <el-table-column prop="emp_email" label="E-postadresse" min-width="230px" />
         <el-table-column prop="employee.name" label="Navn" min-width="230px" />
         <el-table-column label="Ansvarlig" width="100px" align="center">
            <template #default="scope"><el-icon v-if="scope.row.is_manager"><Select /></el-icon></template>
         </el-table-column>
      </el-table>
   </div>
</template>

<script>
import { useAdminEmployeeStore } from "@/store/admin/adminEmployeeStore";
import { useAdminEmpPosStore} from "@/store/admin/adminEmpPosStore";
import { Edit, Delete, Select, Plus } from '@element-plus/icons-vue'
import { shallowRef } from "vue";
import {ElMessageBox} from "element-plus";

export default {
   name: "EmpPositionsTable",
   components: {
      Select,
   },
   setup() {
      const employeeStore = useAdminEmployeeStore()
      const empPositionsStore = useAdminEmpPosStore()
      return { employeeStore, empPositionsStore }
   },
   props: ['department'],
   computed: {
      toggleManagerText() {
         if (!this.selectedRow || !this.selectedRow.is_manager) {
            return 'Gi ansvar'
         }
         return 'Fjern ansvar'
      }
   },
   data() {
      return {
         loading: {all: true},
         selectedRow: null,
         filteredEmployees: [],  // Employees that are shown in the select-input
         empPosForm: {email: null},
         empPosFormRules: {
            email: [{ required: true, message: 'Velg en ansatt.', trigger: 'change' }],
         },
         editIcon: shallowRef(Edit),
         deleteIcon: shallowRef(Delete),
         plusIcon: shallowRef(Plus)
      }
   },
   methods: {
      async loadTable() {
         this.selectedRow = null
         await this.empPositionsStore.getEmployeesForDepartment(this.department)
         this.filterEmployees()
         this.loading.all = false
      },
      // Removes the employees that are already working in the department from the list
      filterEmployees() {
         const allEmployees = this.employeeStore.employees
         const thisDepEmployees = this.empPositionsStore.employeePositions[this.department.id] || []

         this.filteredEmployees = allEmployees.filter(function(emp) {
            for (let i = 0; i < thisDepEmployees.length; i++) {
               if (thisDepEmployees[i].emp_email === emp.email) {
                  return false
               }
            }
            return true
         })
      },
      // Gets called when clicking a row. Selects or de-selects it
      rowClicked(row) {
         if (this.selectedRow === row) {  // if clicked second time (if already selected)
            this.selectedRow = null
            this.$refs.empPosTableRef.setCurrentRow(null)
         } else {  // if clicked first time
            this.selectedRow = row
         }
      },
      async addEmpPosClicked() {
         await this.$refs.empPosForm.validate((isValid) => {
            if (isValid) {
               this.addEmpPos()
            }
            return isValid
         })
      },
      async addEmpPos() {
         this.loading.all = true
         const employeePosition = {
            dep_id: this.department.id,
            emp_email: this.empPosForm.email,
            is_manager: false
         }

         this.$refs.empPosForm.resetFields()
         await this.empPositionsStore.postEmployeePosition(employeePosition)
         await this.loadTable()
      },
      async toggleEmpPosManagerClicked() {
         this.loading.all = true
         const employeePosition = {
            dep_id: this.department.id,
            emp_email: this.selectedRow.emp_email,
            is_manager: !this.selectedRow.is_manager
         }

         await this.empPositionsStore.updateEmployeePosition(employeePosition)
         await this.loadTable()

      },
      deleteEmpPosClicked() {
         ElMessageBox.confirm(
             'Er du sikker på at du vil fjerne ' + this.selectedRow.emp_email + ' fra selskapet?',
             'Bekreft valg',
             {confirmButtonText: 'OK', cancelButtonText: 'Avbryt', type: 'warning'}
         ).then(async () => {
            this.loading.all = true
            await this.empPositionsStore.deleteEmployeePosition(this.selectedRow)
            await this.loadTable()
         }).catch(() => {})
      },
   },
   async mounted() {
      await this.loadTable()

   }
}
</script>

<style scoped>

</style>