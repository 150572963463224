<template>
   <!-- The sidebar menu -->
   <el-menu
       router
       :default-active="this.activeMenu"
       @select="handleSelect"
       background-color="#1f2938"
       active-text-color="#ffd04b"
       text-color="white">

      <img src="@/assets/RetramsLogo.png">

      <ActiveHourTimer id="timer" @timer-stop-clicked="showTimerAddHourDialog = true" :style="authStore.isAdmin || authStore.isManager ? 'margin-bottom:5px;' : 'margin-bottom:10px'"/>
      <el-button v-if="authStore.isAdmin || authStore.isManager" class="fake-menuitem-button" @click="showMenuAddHourDialog = true">
         <el-icon :size="18"><Plus /></el-icon>
         <span>Legg til ny time</span>
      </el-button>
      <el-divider class="menu-divider" />

      <el-scrollbar style="height: calc(100% - 415px)">

         <el-menu-item index="main-my-hours"><el-icon><Calendar /></el-icon>Mine timer</el-menu-item>
         <el-menu-item index="main-my-proj"><el-icon><TakeawayBox /></el-icon>Mine prosjekter</el-menu-item>
         <el-menu-item index="main-my-rep"><el-icon><DataLine /></el-icon>Mine rapporter</el-menu-item>

         <!-- Menu-items that are only visible for department managers or admins -->
         <el-divider class="menu-divider" v-if="authStore.isAdmin || authStore.isManager" />
         <el-sub-menu v-if="authStore.isAdmin || authStore.isManager" index="mng">
            <template #title>
               <el-icon><Management /></el-icon>
               <span>Ledelse</span>
            </template>
            <el-menu-item index="mng-hours" route="/manager"><el-icon><Calendar /></el-icon>Timer</el-menu-item>
            <el-menu-item index="mng-proj" route="/manager"><el-icon><TakeawayBox /></el-icon>Prosjekter</el-menu-item>
            <el-menu-item index="mng-rep" route="/manager"><el-icon><DataLine /></el-icon>Rapporter</el-menu-item>
         </el-sub-menu>

         <el-sub-menu v-if="authStore.isAdmin" index="admin">
            <template #title>
               <el-icon><Operation /></el-icon>
               <span>Admin</span>
            </template>
            <el-menu-item index="admin-emp" route="/admin"><el-icon><User /></el-icon>Ansatte</el-menu-item>
            <el-menu-item index="admin-dep" route="/admin"><el-icon><OfficeBuilding /></el-icon>Selskap</el-menu-item>
         </el-sub-menu>

      </el-scrollbar>

      <el-divider id="user-menu-item-divider" />

      <!-- User info row -->
      <el-popover placement="top" width="240px" :offset="0" popper-class="user-info-popper">
         <template #reference>
            <el-container id="user-menu-item">
               <el-aside width="40px">
                  <el-avatar :size="40">{{ initials }}</el-avatar>
               </el-aside>

               <el-main id="user-menu-item-inforow">
                  <el-space direction="vertical" :size="0" alignment="left">
                     <el-text truncated style="font-size: 14px; font-weight: 550">{{ authStore.name }}</el-text>
                     <el-text truncated style="font-size: 12px">{{ authStore.email }}</el-text>
                  </el-space>
               </el-main>

               <el-aside width="20px">
                  <el-icon id="user-menu-item-moreicon"><MoreFilled /></el-icon>
               </el-aside>
            </el-container>
         </template>

         <!-- Dark mode switch -->
         <el-menu-item index="8" disabled class="user-menu-popover-item" id="dark-mode-menu-item">
            <el-icon>
               <Sunny v-if="!appStore.darkMode" />
               <Moon v-else />
            </el-icon>
            <span>Nattmodus</span>
            <el-switch v-model="appStore.darkMode" @change="appStore.toggleDarkMode()"></el-switch>
         </el-menu-item>

         <!-- Change password and log out buttons -->
         <el-button class="fakepop fake-menuitem-button" @click="showChangePwDialog = true">
            <el-icon :size="18"><Setting /></el-icon>
            <span>Endre passord</span>
         </el-button>
         <el-menu-item index="log-out" class="user-menu-popover-item" @click="logOut()"><el-icon><Close /></el-icon>Logg ut</el-menu-item>
      </el-popover>

   </el-menu>

   <!-- Add new hour form when pressing on 'Add new hour'-button -->
   <el-dialog v-model="showMenuAddHourDialog" title="Legg til ny time" width="650px">
      <AddHourForm ref="menuAddHourForm"/>
      <template #footer>
         <el-button @click="showMenuAddHourDialog = false">Avbryt</el-button>
         <el-button @click="$refs.menuAddHourForm.resetFormClicked()">Nullstill</el-button>
         <el-button type="primary" @click="menuAddHourClicked">Legg til time</el-button>
      </template>
   </el-dialog>

   <!-- Add new hour form when stopping timer -->
   <el-dialog v-model="showTimerAddHourDialog" title="Legg til ny time" width="650px" destroy-on-close>
      <AddHourForm ref="timerAddHourForm" from-timer />
      <template #footer>
         <el-button @click="showTimerAddHourDialog = false">Avbryt</el-button>
         <el-button @click="$refs.timerAddHourForm.resetFormClicked()">Nullstill</el-button>
         <el-button type="primary" @click="timerAddHourClicked">Legg til time</el-button>
      </template>
   </el-dialog>

   <!-- Change password form dialog -->
   <el-dialog v-model="showChangePwDialog" title="Endre passord" width="500px">
      <el-form ref="changePwForm" :model="changePwForm" label-position="left" label-width="150px" :rules="changePwFormRules">
         <el-form-item label="Nåværende passord" prop="currentPw">
            <el-input v-model="changePwForm.currentPw" placeholder="skriv ditt nåværende passord"  type="password" clearable show-password />
         </el-form-item>
         <el-form-item label="Nytt passord" prop="newPw">
            <el-input v-model="changePwForm.newPw" placeholder="skriv nytt passord"  type="password" clearable show-password/>
         </el-form-item>
         <el-form-item label="Bekreft passord" prop="newPwRepeat">
            <el-input v-model="changePwForm.newPwRepeat" placeholder="skriv nytt passord" type="password" clearable show-password />
         </el-form-item>
      </el-form>
      <template #footer>
         <el-button @click="showChangePwDialog = false">Avbryt</el-button>
         <el-button type="primary" @click="changePwSubmitClicked">Endre passord</el-button>
      </template>
   </el-dialog>


</template>

<script>
import { useAppStore } from "@/store/appStore";
import { useAuthStore } from "@/store/authStore";
import { useHourStore } from "@/store/hourStore";
import { Constants } from "@/constants";
import 'element-plus/theme-chalk/dark/css-vars.css'
import { Plus, Calendar, TakeawayBox, DataLine, Management, User, OfficeBuilding, Operation } from '@element-plus/icons-vue'
import { MoreFilled, Setting, Close, Sunny, Moon } from '@element-plus/icons-vue'
import router from "@/router";
import ActiveHourTimer from "@/components/DashboardView/ActiveHourTimer.vue";
import AddHourForm from "@/components/DashboardView/AddHourForm.vue";

export default {
   name: "TheMenu",
   components: {
      AddHourForm,
      ActiveHourTimer,
      Plus, Calendar, TakeawayBox, DataLine, Management, User, OfficeBuilding, Operation,
      Sunny, Moon, MoreFilled, Setting, Close
   },
   setup() {
      const appStore = useAppStore()
      const authStore = useAuthStore()
      const hourStore = useHourStore()
      return { appStore, authStore, hourStore }
   },
   data() {
      return {
         showMenuAddHourDialog: false,
         showTimerAddHourDialog: false,
         showChangePwDialog: false,
         changePwForm: {},
         changePwFormRules: {
            currentPw: [{ required: true, message: 'Skriv inn passord.', trigger: 'blur' }],
            newPw: [{ required: true, validator: this.validatorNewPw, trigger: 'blur'}],
            newPwRepeat: [{ required: true, validator: this.validatorNewPwRepeat, trigger: 'blur'}]
         },
      }
   },
   methods: {
      handleSelect(index) {
         this.appStore.activeMenuItem = index
      },
      async menuAddHourClicked() {
         const validated = await this.$refs.menuAddHourForm.submitFormClicked()
         this.showMenuAddHourDialog = !validated
      },
      async timerAddHourClicked() {
         const validated = await this.$refs.timerAddHourForm.submitFormClicked()
         this.showTimerAddHourDialog = !validated
      },
      logOut() {
         this.authStore.logOut()
         router.push('/login')
         router.go()  // Completely reloads page, to reset all Pinia stores
      },
      async changePwSubmitClicked() {
         await this.$refs.changePwForm.validate((isValid) => {
            if (isValid) {
               this.changePw()
            }
         })
      },
      async changePw() {
         const passwordData = {
            current_pw: this.changePwForm.currentPw,
            new_pw: this.changePwForm.newPw,
         }
         const success = await this.authStore.changePw(passwordData)
         if (success) {
            this.showChangePwDialog = false
            this.changePwForm = {}
            this.$refs.changePwForm.resetFields()
         }
      },
      validatorNewPw(rule, value, callback) {
         if (!value) {
            callback(new Error('Skriv inn nytt passord.'))
         } else if (value.length < Constants.PW_MIN_LENGTH) {
            callback(new Error('Passordet må inneholde minst ' + Constants.PW_MIN_LENGTH + ' tegn.'))
         } else if (this.changePwForm.newPwRepeat) {
            this.$refs.changePwForm.validateField('newPwRepeat', () => null)
         }
         callback()

      },
      validatorNewPwRepeat(rule, value, callback) {
         if (!value) {
            callback(new Error('Skriv inn passordet på nytt.'))
         } else if (value !== this.changePwForm.newPw) {
            callback(new Error("Passordene er ikke like."))
         } else {
            callback()
         }
      },
   },
   computed: {
      activeMenu() {
         return this.appStore.activeMenuItem
      },
      initials() {
         const name = this.authStore.name
         const rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu')

         let initials = [...name.matchAll(rgx)] || []
         initials = ((initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')).toUpperCase();

         return initials
      }
   },
}
</script>

<style scoped>
.el-menu {
   position: fixed;
   top: 0;
   bottom: 0;
   width: 278px;
}
.menu-divider {
   width: 85%;
   margin: 5px auto;
}
.el-menu img {
   height: 70px;
   display: block;
   margin-left: auto;
   margin-right: auto;
}
#timer {
   width: 100%;
   margin: 10px auto -20px;
}
.fake-menuitem-button {
   background-color: transparent;
   border: none;
   color: white;
   width: 100%;
   height: 54px;
   justify-content: left;
   padding-left: 20px;
   margin: 0;
   font-weight: 500;
}
.fake-menuitem-button .el-icon {
   margin-left: 3px;
   margin-right: 3px;
}
.fake-menuitem-button:hover {
   background-color: #19212d;
   color: white;
}
.fake-menuitem-button:focus {
   background-color: transparent;
   color: white;
}
.fakepop.fake-menuitem-button {
   padding-left: 10px;
}
#user-menu-item-divider {
   position: absolute;
   bottom: 57px;
   width: 236px;
   margin-left: 20px;
}
#user-menu-item {
   max-height: 90px;
   padding: 20px;
   width: 278px;
   position: absolute;
   bottom: 0;
}
#user-menu-item:hover {
   cursor: pointer;
   background-color: #19212d;
}
#user-menu-item .el-avatar {
   font-size: 16px;
   color: #303133;
}
#user-menu-item-inforow { /* top 3,  right 0, bottom 0, left 8 */
   padding: 3px 0 0 8px;
}
#user-menu-item-inforow .el-text {
   margin: 0;
   color: white;
   max-width: 167px;
}
#user-menu-item-moreicon {
   float: right;
   color: white;
   height: 100%;
}
.user-menu-popover-item {
   color: white;
   padding-left: 10px;
}
.user-menu-popover-item:hover {
   background-color: #19212d;
}
#dark-mode-menu-item {
   opacity: 1;
}
#dark-mode-menu-item:hover {
   cursor: default;
}
#dark-mode-menu-item .el-switch {
   position: absolute;
   right: 15px;
}
</style>
<style>
.user-info-popper {
   padding: 0 !important;
   background-color: #1f2938 !important;
}
.user-info-popper .el-popper__arrow::before {
   background-color: #1f2938 !important;
}
</style>