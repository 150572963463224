<template>

   <!-- Table controls -->
   <el-row class="table-controls">
      <el-button :icon="plusIcon" @click="showDepartmentDialog = true">Nytt selskap</el-button>
      <el-button :icon="editIcon" :disabled="!selectedRow" @click="editDepartmentClicked">Rediger</el-button>
      <el-button :icon="deleteIcon" :disabled="!selectedRow" type="danger" @click="deleteDepartmentClicked">Slett</el-button>
   </el-row>

   <!-- The departments table -->
   <el-table
       ref="tableRef"
       :data="departmentStore.departments"
       v-loading="loading.employeeTable"
       highlight-current-row
       @row-click="rowClicked"
       border stripe>

      <el-table-column prop="id" label="ID" align="center" width="45px" />
      <el-table-column prop="name" label="Navn" />
   </el-table>

   <!-- New/edit department form dialog -->
   <el-dialog v-model="showDepartmentDialog" :title="departmentDialogTitle" width="60%" @close="departmentDialogClosed">

      <el-form ref="departmentForm" :model="departmentForm" label-position="left" label-width="50px" :rules="departmentFormRules" status-icon>
         <el-form-item v-if="departmentIsEditingForm" label="ID" prop="id">
            <el-input v-model="departmentForm.id" disabled />
         </el-form-item>
         <el-form-item label="Navn" prop="name">
            <el-input v-model="departmentForm.name" placeholder="skriv et navn" />
         </el-form-item>
      </el-form>

      <el-row justify="end">
         <el-button v-if="departmentIsEditingForm" type="primary" @click="departmentSubmitClicked(true)">Oppdater selskap</el-button>
      </el-row>

      <EmpPositionsTable v-if="departmentIsEditingForm" :department="selectedRow" />

      <!-- Dialog footer controls -->
      <template #footer>
         <el-button @click="showDepartmentDialog = false">Avbryt</el-button>
         <el-button v-if="!departmentIsEditingForm" type="primary" @click="departmentSubmitClicked(false)">Legg til selskap</el-button>
      </template>
   </el-dialog>
</template>

<script>
import { useAdminDepartmentStore } from "@/store/admin/adminDepartmentStore";
import { useAdminEmpPosStore} from "@/store/admin/adminEmpPosStore";
import { useAdminEmployeeStore } from "@/store/admin/adminEmployeeStore";
import { Edit, Delete, Plus } from '@element-plus/icons-vue'
import { shallowRef } from "vue";
import { ElMessageBox } from "element-plus";
import EmpPositionsTable from "@/components/DashboardView/DashboardViewAdmin/EmpPositionsTable.vue";

export default {
   name: "AdminDepartments",
   components: {
      EmpPositionsTable,
   },
   setup() {
      const employeeStore = useAdminEmployeeStore()
      const departmentStore = useAdminDepartmentStore()
      const empPositionsStore = useAdminEmpPosStore()
      return { employeeStore, departmentStore, empPositionsStore }
   },
   data() {
      return {
         loading: {departmentTable: false},
         departmentForm: {},
         selectedRow: null,
         showDepartmentDialog: false,
         departmentIsEditingForm: false,
         departmentFormRules: {
            name: [{ required: true, min: 2, message: 'Skriv inn et selskapsnavn.', trigger: 'blur' }],
         },
         editIcon: shallowRef(Edit),
         deleteIcon: shallowRef(Delete),
         plusIcon: shallowRef(Plus)
      }
   },
   computed: {
      // Dialog title
      departmentDialogTitle() {
         return this.departmentIsEditingForm ? 'Rediger selskap' : 'Nytt selskap'
      }
   },
   methods: {
      async loadTable() {
         this.loading.departmentTable = true
         this.selectedRow = null
         await this.departmentStore.getAllDepartments()
         this.loading.departmentTable = false
      },
      // Gets called when clicking a row. Selects or de-selects it
      rowClicked(row) {
         if (this.selectedRow === row) {  // if clicked second time (if already selected)
            this.selectedRow = null
            this.$refs.tableRef.setCurrentRow(null)
         } else {  // if clicked first time
            this.selectedRow = row
         }
      },
      async departmentSubmitClicked(isEditing) {
         await this.$refs.departmentForm.validate((isValid) => {
            if (isValid) {
               this.addDepartment(isEditing)
               this.showDepartmentDialog = false
            }
            return isValid
         })
      },
      // Gets called when dialog is closed
      departmentDialogClosed() {
         this.departmentIsEditingForm = false
         // Dialog data gets removed before dialog is closed, so putting a small delay making it unnoticeable
         setTimeout(() => {
            this.departmentForm = {}
            this.$refs.departmentForm.resetFields()
         }, 100)
      },
      async addDepartment(isEditing) {
         this.loading.departmentTable = true
         const department = {
            id: this.departmentForm.id,
            name: this.departmentForm.name,
         }
         console.log("Editing: ", isEditing)
         if (isEditing) {
            await this.departmentStore.updateDepartment(department)
         } else {
            await this.departmentStore.postDepartment(department)
         }

         await this.loadTable()
      },
      // Gets called when clicking on 'Edit department'. Fills the dialog with data from the selected Department
      editDepartmentClicked() {
         this.departmentIsEditingForm = true
         this.showDepartmentDialog = true
         const department = this.selectedRow
         this.departmentForm = {id: department.id, name: department.name}
      },
      deleteDepartmentClicked() {
         ElMessageBox.confirm(
             'Er du sikker på at du vil slette selskap ' + this.selectedRow.name + '?',
             'Bekreft valg',
             {confirmButtonText: 'OK', cancelButtonText: 'Avbryt', type: 'warning'}
         ).then(async () => {
            this.loading.departmentTable = true
            await this.departmentStore.deleteDepartment(this.selectedRow)
            await this.loadTable()
         }).catch(() => {})
      },
   },
   async mounted() {
      await Promise.all([this.loadTable(), this.employeeStore.getAllEmployees()])
   }
}
</script>

<style scoped>

</style>