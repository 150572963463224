<!-- Component for the Hour-timer/stopwatch.
An "active Hour" is an Hour that has been started by the timer, and not ended yet. It is stored in
the database without some attributes like proj_id, end_time and logged_time, therefore they will be undefined.
When the timer is stopped, the "active Hour" is deleted, and a completely new Hour is added to the database -->
<template>

  <el-space direction="vertical" :size="23" v-loading="loading.timer"
            element-loading-background="rgba(31, 41, 56, 0.8)">
    <el-button v-if="this.timerIntervalRef == null" class="timer-button" circle @click="timerStartClicked">
      <el-space direction="vertical" :size="0">
        <el-icon size="50">
          <Timer/>
        </el-icon>
        <p>Start</p>
      </el-space>
    </el-button>

    <el-button v-else class="timer-button" type="danger" plain circle @click="timerStopClicked">
      <el-space direction="vertical" :size="0">
        <el-icon size="50">
          <Timer/>
        </el-icon>
        <p>Stop</p>
      </el-space>
    </el-button>

    <el-text id="timer-text">{{ timerText }}</el-text>
  </el-space>

</template>

<script>
import {useAuthStore} from "@/store/authStore";
import {useHourStore} from "@/store/hourStore";
import {Timer} from '@element-plus/icons-vue'
import {shallowRef} from "vue";

export default {
  name: "ActiveHourTimer",
  components: {
    Timer
  },
  setup() {
    const authStore = useAuthStore()
    const hourStore = useHourStore()
    return {authStore, hourStore}
  },
  inject: ['loading'],
  data() {
    return {
      timerText: '00:00:00',
      timerIcon: shallowRef(Timer),
      timerIntervalRef: null,  // Reference to the interval that runs every second to update the timerText
      showAddHourDialog: false,
    }
  },
  watch: {
    hourStoreActiveHour(newValue) {
      console.log("-----new active----")
      console.log(newValue)
      if (newValue === null) {
        console.log("active is null")
        this.timerText = '00:00:00'
        clearInterval(this.timerIntervalRef)
        this.timerIntervalRef = null
        console.log("Interval ref:")
        console.log(this.timerIntervalRef)
      } else {
        console.log("active is NOT null")
        const hourUTC = new Date(this.hourStore.activeHour['start_time'])
        console.log("HourUTC", hourUTC)
        console.log(hourUTC.toLocaleTimeString())
        // const hourLocal = new Date(hourUTC - (hourUTC.getTimezoneOffset() * 60000)) // TODO: Change to UTC time on database to keep integrity
        const hourLocal = new Date(hourUTC.toISOString())
        this.timerText = this.getElapsedTime(hourLocal)

        this.timerIntervalRef = setInterval(() => {
          this.timerText = this.getElapsedTime(hourLocal)
        }, 1000);
      }
      this.loading.timer = false
    },
  },
  computed: {
    hourStoreActiveHour() {
      return this.hourStore.activeHour
    },
  },
  methods: {
    // Returns the elapsed time from a time, in 'HH:mm:ss' format
    getElapsedTime(time) {
      let timeDelta = ((new Date().valueOf()) - time.valueOf()) / 1000
      const seconds = Math.floor(timeDelta % 60);
      const secondsStr = seconds < 10 ? "0" + seconds : seconds;

      timeDelta = Math.floor(timeDelta / 60);
      let minutes = timeDelta % 60;
      const minutesStr = minutes < 10 ? "0" + minutes : minutes;

      timeDelta = Math.floor(timeDelta / 60);
      const hours = timeDelta % 24;

      timeDelta = Math.floor(timeDelta / 24);
      const days = timeDelta;
      let totalHours = hours + (days * 24); // add days to hours
      let totalHoursStr = totalHours < 10 ? "0" + totalHours : totalHours;

      return totalHoursStr + ":" + minutesStr + ":" + secondsStr;
    },
    async timerStartClicked() {
      this.loading.timer = true
      const startTime = new Date()
      startTime.setMilliseconds(0)

      const newHour = {
        emp_email: this.authStore.email,
        start_time: startTime.toISOString(),
      }

      await this.hourStore.postHour(newHour)
      await this.hourStore.getMyActiveHour()
    },
    timerStopClicked() {
      this.$emit('timerStopClicked')
    }
  },
  async mounted() {
    await this.hourStore.getMyActiveHour()
    this.loading.timer = false
  }
}
</script>

<style scoped>
.timer-button {
  font-size: 17px;
  width: 132px !important;
  height: 132px !important;
  padding-top: 50px !important;
}

.timer-button .el-icon {
  margin-bottom: -10px;
}

#timer-text {
  font-size: 18px;
  font-weight: 500;
  color: white;
}
</style>