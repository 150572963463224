<template>

   <!-- Table controls -->
   <el-row class="table-controls">
      <el-button :icon="plusIcon" @click="showEmployeeDialog = true">Ny ansatt</el-button>
      <el-button :icon="editIcon" :disabled="!selectedRow" @click="editEmployeeClicked">Rediger</el-button>
      <el-button :icon="deleteIcon" :disabled="!selectedRow" type="danger" @click="deleteEmployeeClicked">Slett</el-button>
   </el-row>

   <!-- The employees table -->
   <el-table
       ref="tableRef"
       :data="employeeStore.employees"
       v-loading="loading.employeeTable"
       highlight-current-row
       @row-click="rowClicked"
       border stripe>

      <el-table-column prop="email" label="E-postadresse" min-width="230px" />
      <el-table-column prop="name" label="Navn" min-width="230px" />
      <el-table-column label="Admin" width="70px" align="center">
         <template #default="scope"><el-icon v-if="scope.row.is_admin"><Select /></el-icon></template>
      </el-table-column>
   </el-table>

   <!-- New/edit employee form dialog -->
   <el-dialog v-model="showEmployeeDialog" :title="employeeDialogTitle" width="40%" @close="employeeDialogClosed">

      <el-form ref="employeeForm" :model="employeeForm" label-position="left" label-width="70px" :rules="employeeFormRules" status-icon>
         <el-form-item label="E-post" prop="email">
            <el-input v-model="employeeForm.email" placeholder="skriv en e-postadresse" :disabled="this.employeeIsEditingForm" />
         </el-form-item>
         <el-form-item label="Navn" prop="name">
            <el-input v-model="employeeForm.name" placeholder="skriv et navn" />
         </el-form-item>
         <el-form-item label="Admin"><el-switch v-model="employeeForm.isAdmin" /></el-form-item>
      </el-form>

      <!-- Dialog footer controls -->
      <template #footer>
         <el-button @click="showEmployeeDialog = false">Avbryt</el-button>
         <el-button v-if="this.employeeIsEditingForm" type="warning" @click="employeeResetPwClicked">Tilbakestill passord</el-button>
         <el-button v-if="this.employeeIsEditingForm" type="primary" @click="employeeSubmitClicked(true)">Oppdater ansatt</el-button>
         <el-button v-else type="primary" @click="employeeSubmitClicked(false)">Legg til ansatt</el-button>
      </template>
   </el-dialog>

</template>

<script>
import { useAdminEmployeeStore } from "@/store/admin/adminEmployeeStore";
import { Edit, Delete, Select, Plus } from '@element-plus/icons-vue'
import { shallowRef } from "vue";
import { ElMessageBox } from "element-plus";

export default {
   name: "AdminEmployees",
   components: {
      Select,
   },
   setup() {
      const employeeStore = useAdminEmployeeStore()
      return { employeeStore }
   },
   data() {
      return {
         loading: {employeeTable: false},
         employeeForm: {},
         selectedRow: null,
         showEmployeeDialog: false,
         employeeIsEditingForm: false,
         employeeFormRules: {
            email: [{ required: true, type: 'email', message: 'Skriv inn en gyldig e-postadresse.', trigger: 'blur' }],
            name: [{ required: true, min: 2, message: 'Skriv inn et fullt navn.', trigger: 'blur' }],
         },
         editIcon: shallowRef(Edit),
         deleteIcon: shallowRef(Delete),
         plusIcon: shallowRef(Plus)
      }
   },
   computed: {
      // Dialog title
      employeeDialogTitle() {
         return this.employeeIsEditingForm ? 'Rediger ansatt' : 'Ny ansatt'
      }
   },
   methods: {
      async loadTable() {
         this.loading.employeeTable = true
         this.selectedRow = null
         await this.employeeStore.getAllEmployees()
         this.loading.employeeTable = false
      },
      // Gets called when clicking a row. Selects or de-selects it
      rowClicked(row) {
         if (this.selectedRow === row) {  // if clicked second time (if already selected)
            this.selectedRow = null
            this.$refs.tableRef.setCurrentRow(null)
         } else {  // if clicked first time
            this.selectedRow = row
         }
      },
      async employeeSubmitClicked(isEditing) {
         await this.$refs.employeeForm.validate((isValid) => {
            if (isValid) {
               this.addEmployee(isEditing)
               this.showEmployeeDialog = false
            }
            return isValid
         })
      },
      // Gets called when dialog is closed
      employeeDialogClosed() {
         // Dialog data gets removed before dialog is closed, so putting a small delay making it unnoticeable
         setTimeout(() => {
            this.employeeForm = {}
            this.employeeIsEditingForm = false
            this.$refs.employeeForm.resetFields()
         }, 100)
      },
      async addEmployee(isEditing) {
         this.loading.employeeTable = true
         const employee = {
            email: this.employeeForm.email,
            name: this.employeeForm.name,
            is_admin: this.employeeForm.isAdmin || false,
         }
         console.log("Editing: ", isEditing)
         if (isEditing) {
            await this.employeeStore.updateEmployee(employee)
         } else {
            await this.employeeStore.postEmployee(employee)
         }

         await this.loadTable()
      },
      // Gets called when clicking on 'Edit employee'. Fills the dialog with data from the selected Employee
      editEmployeeClicked() {
         this.employeeIsEditingForm = true
         this.showEmployeeDialog = true
         const employee = this.selectedRow
         this.employeeForm = {email: employee.email, name: employee.name, isAdmin: employee.is_admin}
      },
      async deleteEmployeeClicked() {
         ElMessageBox.confirm(
             'Er du sikker på at du vil slette ansatt ' + this.selectedRow.email + '?',
             'Bekreft valg',
             {confirmButtonText: 'OK', cancelButtonText: 'Avbryt', type: 'warning'}
         ).then(async () => {
            this.loading.employeeTable = true
            await this.employeeStore.deleteEmployee(this.selectedRow)
            await this.loadTable()
         }).catch(() => {})
      },
      employeeResetPwClicked() {
         ElMessageBox.confirm(
             'Er du sikker på at du vil tilbakestille passordet til ' + this.employeeForm.email + '?',
             'Bekreft valg',
             {confirmButtonText: 'OK', cancelButtonText: 'Avbryt', type: 'warning'}
         ).then(async () => {
            this.showEmployeeDialog = false
            await this.employeeStore.resetPwForEmployee(this.employeeForm.email)
         }).catch(() => {})
      }
   },
   async mounted() {
      await this.loadTable()
   }
}
</script>

<style scoped>

</style>