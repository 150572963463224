import { defineStore } from "pinia";
import { HTTP, authHeader } from "@/api";
import { ElMessage } from "element-plus";
import { useAuthStore } from "@/store/authStore";


export const useAdminEmpPosStore = defineStore("adminEmployeePositions", {
   state: () => ({
      authStore: useAuthStore(),
      employeePositions: {}  // key = dep_id, value = all employees in that dep
   }),
   actions: {
      async getEmployeesForDepartment(department) {
         try {
            const response = await HTTP.get('/employee_positions/department/' + department.id, authHeader())
            this.employeePositions[department.id] = response.data
         } catch (error) {
            this.employeePositions[department.id] = null
            if (error.response.status !== 404) {
               ElMessage.error('Feil ved innlasting av ansatte.')
            }
         }
      },
      async postEmployeePosition(employeePosition) {
         try {
            await HTTP.post('employee_positions/', employeePosition, authHeader())
            ElMessage.success('Ansatt ble lagt til i selskap.')
         } catch (error) {
            ElMessage.error('Feil ved tillegging av ansatt i selskap.')
         }
      },
      async updateEmployeePosition(employeePosition) {
         try {
            await HTTP.put('employee_positions/' + employeePosition.dep_id + '/' + employeePosition.emp_email, employeePosition, authHeader())
            ElMessage.success('Ansatt ble oppdatert i selskapet.')
         } catch (error) {
            ElMessage.error('Feil ved endring av ansatt i selskapet.')
         }
      },
      async deleteEmployeePosition(employeePosition) {
         try {
            await HTTP.delete('employee_positions/' + employeePosition.dep_id + '/' + employeePosition.emp_email, authHeader())
            ElMessage.success('Ansatt ble fjernet fra selskap.')
         } catch (error) {
            ElMessage.error('Feil ved fjerning av ansatt fra selskap.')
         }
      },
   }

})