import {useAuthStore} from "@/store/authStore";
import {defineStore} from "pinia";
import {HTTP, authHeader} from "@/api";
import {ElMessage} from "element-plus";

export const useManagerEmployeeStore = defineStore("managerEmployee", {
    state: () => ({
        authStore: useAuthStore(),
        employees: [],
    }),
    getters: {},
    actions: {
        async getEmployeesAsManager(department) {
            try {
                const response = await HTTP.get(`employee_positions/department/${department}`, authHeader())
                let employees = []
                response.data.forEach((row) => {
                    employees.push(row.employee)
                })
                console.log("Set employees", employees)
                this.employees = employees
            } catch (error) {
                ElMessage.error('Feil ved innlasting av ansatte.')
            }
        },
    },
})