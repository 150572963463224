<template>
  <el-row>
    <el-form
        ref="generateReportForm"
        :model="addForm"
        :rules="formRules"
        status-icon
        label-position="left"
        label-width="135px"
        style="max-width: 570px">

      <el-form-item label="Bare ett selskap">
        <el-switch v-model="addForm.onlyForSelectedCompany"/>
      </el-form-item>

      <el-form-item label="Selskap" prop="department">
        <el-select v-model="addForm.department" placeholder="velg et selskap" style="max-width: 319px; width: 319px;" :disabled="!addForm.onlyForSelectedCompany">
          <el-option v-if="loading.departments" v-loading="loading.departments" value="" disabled/>
          <!--<el-option v-for="proj in projStore.projects" :label="proj.name" :value="proj.id" :key="proj.id" />-->
          <el-option-group>
            <el-option v-for="group in departmentStore.departments" :value="group.id" :key="group.id"
                       :label="group.name"/>
          </el-option-group>
        </el-select>
      </el-form-item>



      <el-button type="primary" size="large" @click="generateReport" v-loading="loading.report">Generer rapport</el-button>
    </el-form>

  </el-row>
</template>

<script setup>
import {inject, onMounted, ref} from "vue";
import {useDepartmentStore} from "@/store/departmentStore";
import {HTTP, authHeader} from "@/api";
import {useAuthStore} from "@/store/authStore";


// eslint-disable-next-line no-undef
const props = defineProps({
  start_range: Number,
  end_range: Number,
})

const authStore = useAuthStore();
const departmentStore = useDepartmentStore();


const generateReportForm = ref(null);

const addForm = ref({
  department: "",
  onlyForSelectedCompany: false,
})
const formRules = {
  department: [{required: true, message: 'Velg et selskap.', trigger: 'change'}],
}

const loading = inject('loading')

const generateReport = async () => {
  const valid = await generateReportForm.value.validate();

  if (valid) {
    try {
      let requestHeaders = authHeader();
      requestHeaders.responseType = "blob";
      console.log(requestHeaders);
      let empUrl = `/hours/employee/${authStore.email}/report?range_start=${props.start_range}&range_end=${props.end_range}`
      if (addForm.value.onlyForSelectedCompany) {
        empUrl = `/hours/employee/${authStore.email}/report?range_start=${props.start_range}&range_end=${props.end_range}&department_id=${addForm.value.department}`
      }
      const response = await HTTP.get(empUrl, requestHeaders);
      console.log(response);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      console.log(url)
      const link = document.createElement('a');
      link.href = url;

      // Try to extract filename from the Content-Disposition header
      const contentDisposition = response.headers['content-disposition'];
      console.log("Content-Disposition: ", contentDisposition);
      let filename = 'default-filename.xlsx';
      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(/filename="?(.+)"?/);
        if (filenameMatch.length === 2)
          filename = filenameMatch[1];
      }

      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (e) {
      console.error(e);
    }
  }
}


onMounted(async () => {
  await departmentStore.getAllMyDepartments();
  if (departmentStore.departments.length > 0) {
    addForm.value.department = departmentStore.departments[0].id;
  }
  loading.departments = false;
})


</script>

<style scoped>

</style>