<!-- Form for adding new Hour -->
<template>

  <el-form
      ref="addHourForm"
      :model="addForm"
      v-loading="loading.newHour"
      :rules="formRules"
      status-icon
      label-position="left"
      label-width="85px"
      style="max-width: 570px">

    <!-- Project input -->
    <el-form-item label="Prosjekt" prop="project">
      <el-select v-model="addForm.project" placeholder="velg et prosjekt" style="max-width: 219px">
        <el-option v-if="loading.projects" v-loading="loading.projects" value="" disabled/>
        <!--<el-option v-for="proj in projStore.projects" :label="proj.name" :value="proj.id" :key="proj.id" />-->
        <el-option-group v-for="group in projStore.myProjectsGrouped" :key="group.dep_id" :label="group.name">
          <el-option v-for="proj in group" :label="proj.name" :value="proj.id" :key="proj.id"/>
        </el-option-group>
      </el-select>
    </el-form-item>

    <!-- Start time input -->
    <el-form-item label="Starttid" required>
      <el-col :span="11">
        <el-form-item prop="startDate">
          <el-date-picker
              v-model="addForm.startDate"
              type="date"
              placeholder="velg en dag"
              :disabled-date="disabledDate"
              :shortcuts="addDateShortcuts"
              @change="startDateChanged(); dateOrTimeChanged();"
              format="DD. MMM YYYY"
              :disabled="!authStore.isAdmin || !authStore.isManager">
          </el-date-picker>
        </el-form-item>
      </el-col>
      <el-col :span="2" style="text-align: center"><span>-</span></el-col>
      <el-col :span="11">
        <el-form-item prop="startTime">
          <el-time-picker
              v-model="addForm.startTime"
              placeholder="velg et tidspunkt"
              @change="dateOrTimeChanged()"
              format="HH:mm"
              value-format="HH:mm"
              :disabled="!authStore.isAdmin || !authStore.isManager">
          </el-time-picker>
        </el-form-item>
      </el-col>
    </el-form-item>

    <!-- End time input -->
    <el-form-item label="Sluttid" required prop="fullEndDate">
      <el-col :span="11">
        <el-form-item prop="endDate">
          <el-date-picker
              v-model="addForm.endDate"
              type="date"
              placeholder="velg en dag"
              :disabled-date="disabledDate"
              :shortcuts="addDateShortcuts"
              @change="dateOrTimeChanged()"
              format="DD. MMM YYYY"
          :disabled="!authStore.isAdmin || !authStore.isManager">
          </el-date-picker>
        </el-form-item>
      </el-col>
      <el-col :span="2" style="text-align: center"><span>-</span></el-col>
      <el-col :span="11">
        <el-form-item prop="endTime">
          <el-time-picker
              v-model="addForm.endTime"
              placeholder="velg et tidspunkt"
              @change="dateOrTimeChanged()"
              format="HH:mm"
              value-format="HH:mm"
          :disabled="!authStore.isAdmin || !authStore.isManager">
          </el-time-picker>
        </el-form-item>
      </el-col>
    </el-form-item>

    <!-- Description text input -->
    <el-form-item label="Beskrivelse">
      <el-input
          v-model="addForm.description"
          type="textarea"
          placeholder="skriv en beskrivelse"
          :maxlength="Constants.DESC_MAX_LENGTH"
          show-word-limit>
      </el-input>
    </el-form-item>

    <!-- 'Reset form' and 'Add hour' buttons. Only visible if withButtons prop passed -->
    <el-row v-if="withButtons" id="form-buttons-row" justify="center">
      <el-button @click="resetFormClicked">Nullstill</el-button>
      <el-button type="primary" @click="submitFormClicked">Legg til time</el-button>
    </el-row>
  </el-form>

</template>

<script>
import {useAuthStore} from "@/store/authStore";
import {useProjectStore} from "@/store/projectStore";
import {useHourStore} from "@/store/hourStore";
import {Constants} from "@/constants";

export default {
  name: "AddHourForm",
  setup() {
    const authStore = useAuthStore()
    const hourStore = useHourStore()
    const projStore = useProjectStore()
    return {authStore, hourStore, projStore}
  },
  inject: ['loading'],
  props: {
    withButtons: {
      type: Boolean,
      default: false,
    },
    fromTimer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      Constants: Constants,
      addForm: {},
      onInitValues: {},  // Used to keep initial date+time values when opening from timer, to be used by 'Reset' button
      formRules: {
        project: [{required: true, message: 'Velg et prosjekt.', trigger: 'change'}],
        startDate: [{required: true, type: 'date', message: 'Velg en startdato.', trigger: 'change'}],
        endDate: [{required: true, type: 'date', message: 'Velg en sluttdato.', trigger: 'change'}],
        startTime: [{
          required: true,
          validator: this.validatorTime,
          message: 'Velg et starttidspunkt.',
          trigger: 'change'
        }],
        endTime: [{
          required: true,
          validator: this.validatorTime,
          message: 'Velg et sluttidspunkt.',
          trigger: 'change'
        }],
        fullEndDate: [{
          required: true,
          validator: this.validatorEndIsAfterStart,
          message: 'Sluttid kan ikke være før starttid.'
        }]
      },
      // Shortcuts on left side of date-picker
      addDateShortcuts: [
        {
          text: 'I dag',
          value: new Date(),
        },
        {
          text: 'I går',
          value: () => {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24)
            return date
          },
        },
        {
          text: '2 dager siden',
          value: () => {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 2)
            return date
          },
        },
        {
          text: 'En uke siden',
          value: () => {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
            return date
          },
        }
      ],
    }
  },
  methods: {
    async loadProjects() {
      this.loading.projects = true
      await this.projStore.getMyProjects()
      this.loading.projects = false
    },
    async submitFormClicked() {
      return await this.$refs.addHourForm.validate((isValid) => {
        if (isValid) {
          this.addHour()
        }
        return isValid
      })
    },
    async addHour() {
      this.loading.newHour = true
      this.loading.table = true
      if (this.fromTimer) {
        this.loading.timer = true
      }

      const fullStartDate = this.dateMergeHelper(this.addForm.startDate, this.addForm.startTime)
      const fullEndDate = this.dateMergeHelper(this.addForm.endDate, this.addForm.endTime)

      const newHour = {
        emp_email: this.authStore.email,
        proj_id: this.addForm.project,
        start_time: fullStartDate.toISOString(),
        end_time: fullEndDate.toISOString(),
        description: this.addForm.description
      }

      const success = await this.hourStore.postHour(newHour)
      this.loading.newHour = false

      if (!success) {
        this.loading.table = false
        return
      }

      this.addForm = {}
      if (this.fromTimer) {
        await this.hourStore.deleteActiveHour()
      } else {
        this.$refs.addHourForm.resetFields()
      }
      await this.hourStore.getMyHours()

    },
    // Helper function to combine a date with a time
    dateMergeHelper(date, time) {
      const fullDate = new Date(date)
      const timeSplit = time.split(':')
      fullDate.setHours(timeSplit[0], timeSplit[1])
      return fullDate
    },
    // Returns dates that will be disabled in the date-picker.
    disabledDate(time) {
      // All future dates
      return time.getTime() > Date.now()
    },
    // Gets called when start date in the date-picker is changed
    startDateChanged() {
      // Automatically set endDate to startDate if its empty, or if endDate is before startDate
      if (this.addForm.endDate == null || this.addForm.endDate < this.addForm.startDate) {
        this.addForm.endDate = this.addForm.startDate
        this.$refs.addHourForm.validateField('endDate').catch(() => {
        })
      }
    },
    // If any of the date- or time-pickers changed, validate if end is after start
    dateOrTimeChanged() {
      this.$refs.addHourForm.validateField('fullEndDate').catch(() => {
      })
    },
    // Validator for time-picker
    validatorTime(rule, value, callback) {
      const regex = new RegExp(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/)  // HH:mm with leading 0
      if (regex.test(value)) {
        callback()
      }
      callback(new Error())
    },
    // Validator for date- and time-picker, to check if end is after start
    validatorEndIsAfterStart(rule, value, callback) {
      if (this.addForm.startDate == null || this.addForm.startTime == null ||
          this.addForm.endDate == null || this.addForm.endTime == null) {
        return callback()
      }

      const fullStartDate = this.dateMergeHelper(this.addForm.startDate, this.addForm.startTime)
      const fullEndDate = this.dateMergeHelper(this.addForm.endDate, this.addForm.endTime)
      if (fullEndDate < fullStartDate) {
        return callback(new Error())
      }

      return callback()
    },
    // Resets the form values and validation
    resetFormClicked() {
      this.addForm = {}
      this.$refs.addHourForm.resetFields()

      if (this.fromTimer) {
        this.addForm = {...this.onInitValues}
      }
    }
  },
  async mounted() {
    // Autofill start and end if opened from timer
    if (this.fromTimer) {
      const startUTC = new Date(this.hourStore.activeHour['start_time'])
      // const startLocal = new Date(startUTC - (startUTC.getTimezoneOffset() * 60000)) // TODO: Change to UTC time on database to keep integrity
      const startLocal = new Date(startUTC.toISOString())
      this.addForm.startDate = startLocal
      const startTime = startLocal.toTimeString().substring(0, 5)
      this.addForm.startTime = startTime

      const end = new Date()
      end.setMilliseconds(0)
      this.addForm.endDate = end
      const endTime = end.toTimeString().substring(0, 5)
      this.addForm.endTime = endTime

      this.onInitValues = {startDate: startLocal, startTime, endDate: end, endTime}
      console.log(this.onInitValues)
    }
    await this.loadProjects()
  }
}
</script>

<style scoped>
#form-buttons-row .el-button {
  width: 120px;
}
</style>