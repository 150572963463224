import { defineStore } from "pinia";
import { HTTP, authHeader } from "@/api";
import { ElMessage } from "element-plus";
import { useAuthStore } from "@/store/authStore";

export const useAdminEmployeeStore = defineStore("adminEmployees", {
   state: () => ({
      authStore: useAuthStore(),
      employees: null,
   }),
   actions: {
      async getAllEmployees() {
         try {
            const response = await HTTP.get('employees/', authHeader())
            this.employees = response.data
         } catch(error) {
            this.employees = []
            ElMessage.error('Feil ved innlasting av ansatte.')
         }
      },
      async postEmployee(employee) {
         try {
            await HTTP.post('employees/', employee, authHeader())
            ElMessage.success('Ansatt ble lagt til.')
         } catch (error) {
            ElMessage.error('Feil ved tillegging av ansatt.')
         }
      },
      async updateEmployee(employee) {
         try {
            await HTTP.put('employees/' + employee.email, employee, authHeader())
            ElMessage.success('Ansatt ble oppdatert.')
         } catch (error) {
            ElMessage.error('Feil ved oppdatering av ansatt.')
         }
      },
      async resetPwForEmployee(employeeEmail) {
         try {
            await HTTP.put('auth/password/' + employeeEmail, {}, authHeader())
            ElMessage.success('Passord til ansatt ble tilbakestilt.')
         } catch (error) {
            ElMessage.error('Feil ved tilbakestilling av passord til ansatt.')
         }
      },
      async deleteEmployee(employee) {
         try {
            await HTTP.delete('employees/' + employee.email, authHeader())
            ElMessage.success('Ansatt ble slettet.')
         } catch (error) {
            ElMessage.error('Feil ved sletting av ansatt.')
         }
      },
   }

})