<template>
  <ManagerHours v-if="activeMenu === 'mng-hours'"/>
  <ManagerProjects v-else-if="activeMenu === 'mng-proj'"/>
  <ManagerReports v-else-if="activeMenu ==='mng-rep'"/>
</template>

<script>
import {useAppStore} from "@/store/appStore";
import ManagerHours from "@/components/DashboardView/DashboardViewManager/ManagerHours.vue";
import ManagerProjects from "@/components/DashboardView/DashboardViewManager/ManagerProjects.vue";
import ManagerReports from "@/components/DashboardView/DashboardViewManager/ManagerReports.vue";

export default {
  name: "DashboardViewManager",
  components: {
    ManagerReports,
    ManagerHours,
    ManagerProjects
  },
  setup() {
    const appStore = useAppStore()
    return {appStore}
  },
  computed: {
    activeMenu() {
      return this.appStore.activeMenuItem
    }
  },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style scoped>

</style>