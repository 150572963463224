<template>
   <el-collapse style="margin-bottom: 50px">
      <el-collapse-item>
         <template #title>
            <p style="font-size: 18px">Legg til time</p>
         </template><br>
         <AddHourForm with-buttons />
      </el-collapse-item>
   </el-collapse>

   <MyHoursTable />
</template>

<script>
import MyHoursTable from "@/components/DashboardView/DashboardViewMain/MyHours/MyHoursTable.vue";
import AddHourForm from "@/components/DashboardView/AddHourForm.vue";

export default {
   name: "MyHours",
   components: {
      AddHourForm,
      MyHoursTable,
   },

}
</script>

<style scoped>
</style>
