<template>
   <MyHours v-if="activeMenu === 'main-my-hours'" />
   <MyProjects v-else-if="activeMenu === 'main-my-proj'" />
   <MyReports v-else-if="activeMenu === 'main-my-rep'" />
</template>

<script>
import MyHours from "@/components/DashboardView/DashboardViewMain/MyHours/MyHours.vue";
import { useAppStore } from "@/store/appStore";
import MyProjects from "@/components/DashboardView/DashboardViewMain/MyProjects.vue";
import MyReports from "@/components/DashboardView/DashboardViewMain/MyReports.vue";

export default {
   name: "DashboardViewMain",
   components: {
      MyReports,
      MyProjects,
      MyHours,
   },
   setup() {
      const appStore = useAppStore()
      return { appStore }
   },
   computed: {
      activeMenu() {
         return this.appStore.activeMenuItem
      }
   },
   data() {
      return {

      }
   },
}
</script>

<style scoped>

</style>