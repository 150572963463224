import { defineStore } from "pinia";
import { HTTP, authHeader } from "@/api";
import { ElMessage } from "element-plus";
import { useAuthStore } from "@/store/authStore";


export const useAdminDepartmentStore = defineStore("adminDepartments", {
   state: () => ({
      authStore: useAuthStore(),
      departments: null,
   }),
   actions: {
      async getAllDepartments() {
         try {
            const response = await HTTP.get('departments/', authHeader())
            this.departments = response.data
         } catch(error) {
            this.departments = []
            ElMessage.error('Feil ved innlasting av selskap.')
         }
      },
      async postDepartment(department) {
         try {
            await HTTP.post('departments/', department, authHeader())
            ElMessage.success('Selskap ble lagt til.')
         } catch (error) {
            ElMessage.error('Feil ved tillegging av selskap.')
         }
      },
      async updateDepartment(department) {
         try {
            await HTTP.put('departments/' + department.id, department, authHeader())
            ElMessage.success('Selskap ble oppdatert.')
         } catch (error) {
            ElMessage.error('Feil ved oppdatering av selskap.')
         }
      },
      async deleteDepartment(department) {
         try {
            await HTTP.delete('departments/' + department.id, authHeader())
            ElMessage.success('Selskap ble slettet.')
         } catch (error) {
            ElMessage.error('Feil ved sletting av selskap.')
         }
      },
   }

})