import {useAuthStore} from "@/store/authStore";
import {defineStore} from "pinia";
import {HTTP, authHeader} from "@/api";
import {ElMessage} from "element-plus";

export const useProjectStore = defineStore("projects", {
    state: () => ({
        authStore: useAuthStore(),
        projects: [],
        projectsGrouped: [],
        projectsGroupedListView: [],
        projectsInDepartment: [],
        myProjects: [],
        myProjectsGrouped: [],
        myProjectsGroupedListView: [],
    }),
    getters: {
        email: (state) => state.authStore.email
    },
    actions: {
        async getMyProjects() {
            try {
                const response = await HTTP.get('projects/employee/' + this.email, authHeader())
                this.myProjects = response.data
                this.formatMyProjectsListView()
                this.formatMyProjects()
            } catch (error) {
                console.log(error)
                ElMessage.error('Feil ved innlasting av prosjekter ')
            }
        },
        async getAllProjectsManager() {
            try {
                const response = await HTTP.get('projects/management/' + this.email, authHeader())
                this.projects = response.data
                console.log("Projects loaded: ", this.projects)
                this.formatProjects()
                this.formatProjectsListView()
            } catch (error) {
                console.log(error)
                ElMessage.error('Feil ved innlasting av prosjekter')
            }
        },
        async postProject(project) {
            try {
                const response = await HTTP.post('projects/', project, authHeader())
                if (response.status === 200) {
                    ElMessage.success('Prosjekt ble opprettet.')
                } else {
                    ElMessage.error('Feil ved oppretting av prosjekt')
                }
            } catch (error) {
                console.log(error)
                ElMessage.error('Feil ved oppretting av prosjekt ')
            }
        },
        async updateProject(project, id = null) {
            if (id === null) {
                ElMessage.error('Feil ved oppdatering av prosjekt, mangler id')
                return
            }
            try {
                project.id = id;
                const response = await HTTP.put('projects/' + id, project, authHeader())
                if (response.status === 200) {
                    ElMessage.success('Prosjekt ble oppdatert.')
                } else {
                    ElMessage.error('Feil ved oppdatering av prosjekt')
                }
            } catch (error) {
                console.log(error)
                ElMessage.error('Feil ved oppdatering av prosjekt')
            }
        },
        async deleteProject(project) {
            try {
                const response = await HTTP.delete('projects/' + project.id, authHeader())
                if (response.status === 200) {
                    ElMessage.success('Prosjekt ble slettet.')
                } else {
                    ElMessage.error('Feil ved sletting av prosjekt')
                }
            } catch (error) {
                console.log(error)
                ElMessage.error('Feil ved sletting av prosjekt')
            }
        },
        // Groups the projects by department, so the Select-element can display the projects by group
        formatProjects() {
            this.projectsGrouped = this.projects.reduce((x, y) => {
                (x[y.dep_id] = x[y.dep_id] || []).push(y);
                x[y.dep_id].dep_id = y.dep_id
                x[y.dep_id].name = y.dep_id.name
                return x;
            }, {});
        },
        formatMyProjects() {
            this.myProjectsGrouped = this.myProjects.reduce((x, y) => {
                (x[y.dep_id] = x[y.dep_id] || []).push(y);
                x[y.dep_id].dep_id = y.dep_id
                x[y.dep_id].name = y.dep_id.name
                return x;
            }, {});
        },
        formatProjectsListView() {
            let tempProjects = []
            let departments = this.projects.reduce((x, y) => {
                (x[y.dep_id] = x[y.dep_id] || []).push(y.dep_id);
                return x
            }, [])
            departments.forEach(department => {
                this.projects.forEach(project => {
                    if (project.dep_id === department[0]) {
                        tempProjects.push(project)
                    }
                })
            })
            this.projectsGroupedListView = tempProjects;
        },
        formatMyProjectsListView() {
            let tempProjects = []
            let departments = this.myProjects.reduce((x, y) => {
                (x[y.dep_id] = x[y.dep_id] || []).push(y.dep_id);
                return x;
            }, [])
            // Populate the departments
            departments.forEach(department => {
                this.myProjects.forEach(project => {
                    if (project.dep_id === department[0]) {
                        tempProjects.push(project)
                    }
                })
            })
            this.myProjectsGroupedListView = tempProjects
        },
        getProjectsForDepartment(department) {
            let projects = this.projects.filter(project => project.dep_id === department);
            this.projectsInDepartment = projects.reduce((x, y) => {
                (x[y.dep_id] = x[y.dep_id] || []).push(y);
                x[y.dep_id].dep_id = y.dep_id
                x[y.dep_id].name = y.dep_id.name
                return x;
            }, {});

            return this.projectsInDepartment;
        },
        getProjectsForDepartmentReport(department) {
            let projects = [];
            console.log("Projects in projects",this.projects)
            this.projects.forEach(project => {
                if (project.dep_id === department) {
                    projects.push(project)
                }
            })
            return projects;
        },
        getProjectsForMyDepartmentReport(department) {
            let projects = [];
            console.log("Projects in projects",this.myProjects)
            this.myProjects.forEach(project => {
                if (project.dep_id === department) {
                    projects.push(project)
                }
            })
            return projects;
        }
    }

})