<template>
   <!-- Main router -->
   <router-view></router-view>
</template>

<script>

import { useAppStore } from "@/store/appStore";

export default {
   name: 'App',
   setup() {
      const appStore = useAppStore()
      return { appStore }
   },
   mounted() {
      document.getElementById('html-page').classList.toggle('dark', this.appStore.darkMode)
   }
}
</script>

<style>
html {
   font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, 微软雅黑, Arial, sans-serif;
}
body {
   margin: 0;
}
.table-controls {
   margin-bottom: 15px;
}
</style>
