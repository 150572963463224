<template>

   <AdminEmployees v-if="activeMenu === 'admin-emp'" />
   <AdminDepartments v-else-if="activeMenu === 'admin-dep'" />

</template>

<script>
import { useAppStore } from "@/store/appStore";
import AdminEmployees from "@/components/DashboardView/DashboardViewAdmin/AdminEmployees.vue";
import AdminDepartments from "@/components/DashboardView/DashboardViewAdmin/AdminDepartments.vue";

export default {
   name: "DashboardViewAdmin",
   components: {
      AdminEmployees,
      AdminDepartments
   },
   setup() {
      const appStore = useAppStore()
      return { appStore }
   },
   computed: {
      activeMenu() {
         return this.appStore.activeMenuItem
      }
   },
   data() {
      return {}
   },
   methods: {}
}
</script>

<style scoped>
</style>