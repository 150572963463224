<template>

  <el-collapse style="margin-bottom: 50px">
    <el-collapse-item>
      <template #title>
        <p style="font-size: 18px">Last ned rapport</p>
      </template>
      <br>
      <div class="container">
        <el-radio-group v-model="rapportRadio">
          <el-radio-button label="employee">Alle timer i tidsrommet</el-radio-button>
          <el-radio-button label="project">Prosjekt</el-radio-button>
        </el-radio-group>
        <el-form-item label="Kvartals rapport">
          <el-switch v-model="quarterlyReport" :disabled="tableRangeType!=='month'"/>
        </el-form-item>
        <div class="left-align">
          <p style="font-size: 14px">Fra: {{ dateFormatter(start_range * 1000) }} </p>
          <p style="font-size: 14px">Til men ikke med : {{ dateFormatter(end_range * 1000) }} </p>
        </div>
        <CreateReport v-if="rapportRadio==='employee'" :start_range="start_range" :end_range="end_range"/>
        <CreateProjectReport v-if="rapportRadio==='project'" :start_range="start_range" :end_range="end_range"/>
      </div>
    </el-collapse-item>
  </el-collapse>

  <!-- Table controls -->
  <el-row justify="space-between" class="table-controls">

    <!-- View mode picker (calender/list) -->
    <el-radio-group v-model="viewModeRadio">
      <!--      <el-radio-button label="cal">Kalender</el-radio-button>-->
      <el-radio-button label="list">Liste</el-radio-button>
    </el-radio-group>

    <!-- Date range picker -->
    <el-row>
      <el-button @click="tableRangeArrowsClicked(-1)" :icon="arrowLeft"/>
      <el-date-picker
          ref="rangePickerRef"
          v-model="tableRange"
          @change="tableRangeChanged"
          @visible-change="rangePickerClosed"
          range-separator="To"
          :type="tableRangeType"
          :format="tableRangeFormat"
          style="width: 150px"/>
      <el-button @click="tableRangeArrowsClicked(1)" :icon="arrowRight"/>
    </el-row>

    <!-- Date range type picker (day/week/month) -->
    <el-radio-group v-model="tableRangeType" @change="tableRangeTypeChanged">
      <el-radio-button label="date">Dag</el-radio-button>
      <el-radio-button label="week">Uke</el-radio-button>
      <el-radio-button label="month">Måned</el-radio-button>
    </el-radio-group>
  </el-row>
  <el-table
      ref="tableRef"
      :data="projectStore.myProjectsGroupedListView"
      v-loading="loading.projectTable"
      highlight-current-row
      border stripe>


    <el-table-column label="Selskap" min-width="210px">
      <template #default="scope">
        {{
          departmentStore !== undefined && departmentStore.getDepartment(scope.row.dep_id) !== undefined ? departmentStore.getDepartment(scope.row.dep_id).name || "Loading" : "Loading"
        }}
      </template>
    </el-table-column>
    <el-table-column prop="name" label="Prosjekt" min-width="210px"/>
    <el-table-column label="Timer ført" width="110px" align="center">
      <template #default="scope">
        {{ hoursEachProject[scope.row.id] }}
      </template>
    </el-table-column>
  </el-table>
</template>

<script setup>
import {useProjectStore} from "@/store/projectStore";
import {useDepartmentStore} from "@/store/departmentStore";
import {useHourStore} from "@/store/hourStore";
import {computed, onMounted, ref, shallowRef} from "vue";
import {ArrowLeft, ArrowRight} from "@element-plus/icons-vue";
import CreateReport from "@/components/DashboardView/DashboardViewMain/MyReports/CreateReport.vue";
import CreateProjectReport from "@/components/DashboardView/DashboardViewMain/MyReports/CreateProjectReport.vue";
import {dayjs} from "element-plus";

const projectStore = useProjectStore()
const departmentStore = useDepartmentStore()
const hourStore = useHourStore()

const loading = ref({projectTable: false})
const viewModeRadio = ref('list');
const rapportRadio = ref('employee');
const quarterlyReport = ref(false);
const tableRange = ref(new Date())
const tableRangeType = ref('month')
const tableRangeTypePrevious = ref('month')
const rangePickerSelectedNew = ref(true)
const arrowLeft = shallowRef(ArrowLeft)
const arrowRight = shallowRef(ArrowRight)
const hoursEachProject = ref({})

const tableRangeFormat = computed(() => {
  switch (tableRangeType.value) {
    case 'week':
      return '[Uke] ww YYYY'
    case 'month':
      return 'MMM YYYY'
    default:
      return 'DD. MMM YYYY'
  }
})


const start_range = computed(() => {
  const start = calculateRange()[0]
  return start / 1000;
})

const end_range = computed(() => {
  const end = calculateRange()[1]
  return end / 1000;
})

const loadTable = async () => {
  await departmentStore.getAllMyDepartments()
  loading.value.projectTable = true
  const [start, end] = calculateRange()
  await projectStore.getMyProjects()
  await hourStore.getMyHours(start, end)
  loading.value.projectTable = false
  calculateHoursEachProject()
}

const calculateRange = () => {
  let day = null, diff = null, start = null, end = null

  switch (tableRangeType.value) {
    case 'date':
      start = new Date(tableRange.value)
      end = new Date(start)
      end.setDate(start.getDate() + 1)
      break
    case 'week':
      day = tableRange.value.getDay()
      diff = tableRange.value.getDate() - day + (day === 0 ? -6 : 1)
      start = new Date(tableRange.value)
      start.setDate(diff)
      end = new Date(start)
      end.setDate(start.getDate() + 7)
      break
    case 'month':
      start = new Date(tableRange.value.getFullYear(), tableRange.value.getMonth(), 1)
      if (quarterlyReport.value) {
        end = new Date(tableRange.value.getFullYear(), tableRange.value.getMonth() + 3, 1)
      } else {
        end = new Date(tableRange.value.getFullYear(), tableRange.value.getMonth() + 1, 1)
      }
      break
    case 'year':
      start = new Date(tableRange.value.getFullYear(), 0, 1)
      end = new Date(tableRange.value.getFullYear() + 1, 0, 1)
      break
    default:
      start = new Date(tableRange.value.getFullYear(), tableRange.value.getMonth(), 1)
      end = new Date(tableRange.value.getFullYear(), tableRange.value.getMonth() + 1, 1)
      break
  }
  return [start.valueOf(), end.valueOf()]
}

const calculateHoursEachProject = () => {
  console.log("Calculating hours every project")
  projectStore.myProjects.forEach(project => {
    let acumulatedHours = 0
    hourStore.hours.forEach(hour => {
      if (hour.proj_id === project.id) {
        const start_time = new Date(hour.start_time);
        const end_time = new Date(hour.end_time);
        let duration = (end_time.valueOf() - start_time.valueOf()) / 1000 / 60 / 60
        const lunchStart = new Date(hour.start_time);
        lunchStart.setHours(12, 0, 0);
        const lunchEnd = new Date(hour.end_time);
        lunchEnd.setHours(12, 30, 0);

        console.log("Duration", duration)
        if (duration >= 5.5 && !project.has_paid_lunch) {
          duration = duration - 0.5
        } else if (start_time <= lunchStart && end_time >= lunchEnd && !project.has_paid_lunch) {
          duration -= 0.5
        }
        acumulatedHours += duration
      }
    })
    acumulatedHours = +(Math.round(acumulatedHours + "e+2") + "e-2")
    hoursEachProject.value[project.id] = acumulatedHours
  })
  console.log(hoursEachProject.value)
}

const tableRangeChanged = async () => {
  if (tableRange.value == null) {
    tableRange.value = new Date()
    tableRange.value.setHours(0, 0, 0, 0)
  }

  rangePickerSelectedNew.value = true
  await loadTable()
}

const tableRangeTypeChanged = () => {
  tableRangeTypePrevious.value = tableRangeType.value
  rangePickerSelectedNew.value = false
}

const rangePickerClosed = (visible) => {
  if (!visible && !rangePickerSelectedNew.value) {
    tableRangeType.value = tableRangeTypePrevious.value
  }
}

const tableRangeArrowsClicked = (sign) => {
  let newTimestamp = null
  switch (tableRangeType.value) {
    case 'date':
      newTimestamp = tableRange.value.setDate(tableRange.value.getDate() + (1 * sign))
      break
    case 'week':
      newTimestamp = tableRange.value.setDate(tableRange.value.getDate() + (7 * sign))
      break
    case 'month':
      newTimestamp = tableRange.value.setMonth(tableRange.value.getMonth() + (1 * sign))
      break
  }
  tableRange.value = new Date(newTimestamp)
  tableRangeChanged()
}

const dateFormatter = (date) => {
  return dayjs(date).format(tableRangeFormat.value)
}

onMounted(loadTable)
</script>

<style scoped>
.one-px-border {
  border: 1px solid #848484; /* Adjust color as needed */
  border-radius: 5px;
  padding: 10px;
}

.mb {
  margin-bottom: 30px;
}

.left-align {
  text-align: left;
}
</style>