import {createApp} from 'vue'
import App from './App.vue'
import {createPinia} from "pinia"
import router from "@/router";
import axios from 'axios'
import VueAxios from 'vue-axios';
import ElementPlus, {dayjs} from 'element-plus'
import 'element-plus/dist/index.css'
import {nbNo} from "element-plus/es/locale/index";
import 'dayjs/locale/nb'
// import './index.css'

//
// window.onerror = function (message) {
//     if (message.includes('ResizeObserver loop')) {
//         return true; // this will prevent the error from being reported to the console
//     }
//     // other error handling...
// }


const pinia = createPinia()
const app = createApp(App)
dayjs.locale('nb')
dayjs.Ls.nb.weekdays = "Søndag_Mandag_Tirsdag_Onsdag_Torsdag_Fredag_Lørdag".split("_")

app.use(VueAxios, axios)
app.use(ElementPlus, {locale: nbNo})
app.use(pinia)
app.use(router)

app.mount('#app')
