import { defineStore } from "pinia";

// Used for general app state and user preferences
// TODO move loading-variables here
export const useAppStore = defineStore("app", {
   state: () => ({
      activeMenuItem: 'main-my-hours',
      darkMode: localStorage.getItem("darkMode") === 'true',
   }),
   getters: {
      //yyy: (state) => state.xxx.yyy
   },
   actions: {
      toggleDarkMode() {
         localStorage.setItem("darkMode", this.darkMode)
         document.getElementById('html-page').classList.toggle('dark', this.darkMode)
      },
   }

})