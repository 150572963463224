<template>
  <!-- The project table -->
  <el-table
      ref="tableRef"
      :data="projectStore.myProjectsGroupedListView"
      v-loading="loading.projectTable"
      highlight-current-row
      border stripe>

    <el-table-column prop="name" label="Prosjekt" min-width="210px"/>
    <el-table-column label="Selskap" min-width="210px">
      <template #default="scope">
        {{
          departmentStore !== undefined && departmentStore.getDepartment(scope.row.dep_id) !== undefined ? departmentStore.getDepartment(scope.row.dep_id).name || "Loading" : "Loading"
        }}
      </template>
    </el-table-column>
    <el-table-column label="Betalt Lunsj" width="110px" align="center">
      <template #default="scope">
        <el-icon v-if="scope.row.has_paid_lunch"><Select/></el-icon>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import {useProjectStore} from "@/store/projectStore";
import {useDepartmentStore} from "@/store/departmentStore";
import {shallowRef} from "vue";
import {ArrowDown, Delete, Edit, Plus, Select} from "@element-plus/icons-vue";

export default {
  name: "MyProjects",
  components: {
    Select,
  },
  setup() {
    const projectStore = useProjectStore()
    const departmentStore = useDepartmentStore()
    return {projectStore, departmentStore}
  },
  data() {
    return {
      loading: {projectTable: false},
      editIcon: shallowRef(Edit),
      deleteIcon: shallowRef(Delete),
      plusIcon: shallowRef(Plus),
      ArrowDown: shallowRef(ArrowDown),
    }
  },
  methods: {
    async loadTable() {
      await this.departmentStore.getAllMyDepartments()
      this.loading.projectTable = true
      this.selectedRow = null
      await this.projectStore.getMyProjects()
      this.loading.projectTable = false
    },
  },
  async mounted() {
    await this.loadTable()
  }
}
</script>

<style scoped>

</style>