import {useAuthStore} from "@/store/authStore";
import {defineStore} from "pinia";
import {HTTP, authHeader} from "@/api";
import {ElMessage} from "element-plus";

export const useManagerHourStore = defineStore("managerHours", {
    state: () => ({
        authStore: useAuthStore(),
        hours: [],
        rangeStart: null,
        rangeEnd: null,
    }),
    getters: {},
    actions: {
        async getHoursAsManager(start = this.rangeStart, end = this.rangeEnd) {
            this.rangeStart = start
            this.rangeEnd = end
            let data = authHeader()
            data.params = {range_start: start / 1000, range_end: end / 1000}
            try {
                const response = await HTTP.get('hours/manager', data)
                // The table has a bug where if you change the reference of this.hours, the filter
                // and sorting options will reset when changing the date range. That is why we don't
                // do 'this.hours = response.data' here like usual. The only workaround I have
                // found is to first empty the array, then append each new element to it.
                this.hours = response.data
                // this.hours.length = 0
                // this.hours.splice(0, this.hours.length);
                // console.log("Empty hours", this.hours, response.data)
                // response.data.forEach((row) => {
                //     this.hours.push(row)
                // })
                console.log("Set hours", this.hours)
            } catch (error) {
                this.hours = []
                ElMessage.error('Feil ved innlasting av timer.')
            }
        },
        async deleteHour(hour) {  // Duplicate of hourStore.js
            try {
                await HTTP.delete('hours/' + hour.id, authHeader())
                ElMessage.success('Time ble slettet.')
            } catch (error) {
                ElMessage.error('Feil ved sletting av time.')
            }
        },
    }

})