import {useAuthStore} from "@/store/authStore";
import {defineStore} from "pinia";
import {HTTP, authHeader} from "@/api";
import {ElMessage} from "element-plus";

export const useHourStore = defineStore("hours", {
    state: () => ({
        authStore: useAuthStore(),
        hours: null,
        rangeStart: null,
        rangeEnd: null,
        activeHour: null,
    }),
    getters: {
        email: (state) => state.authStore.email
    },
    actions: {
        async getMyHours(start = this.rangeStart, end = this.rangeEnd) {
            this.rangeStart = start
            this.rangeEnd = end
            let data = authHeader()
            data.params = {range_start: start / 1000, range_end: end / 1000}
            try {
                const response = await HTTP.get('hours/employee/' + this.email, data)
                this.hours = response.data
                console.log("My hours: ", response.data)
            } catch (error) {
                this.hours = []
                ElMessage.error('Feil ved innlasting av timer.')
            }
        },
        async getMyActiveHour() {
            let data = authHeader()
            data.params = {active_hour: true}
            try {
                const response = await HTTP.get('hours/employee/' + this.email, data)
                this.activeHour = response.data
                console.log("Active Hour: ", response.data)
            } catch (error) {
                if (error.response.status === 404) {  // Normal. User hasn't started timer
                    console.log("No active hour")
                }
            }
        },
        async postHour(newHour) {
            try {
                await HTTP.post('hours/', newHour, authHeader())
                ElMessage.success('Time ble lagt til.')
                return true
            } catch (error) {
                ElMessage.error('Feil ved tillegging av time.')
            }
        },
        async deleteHour(hour) {
            try {
                await HTTP.delete('hours/' + hour.id, authHeader())
                ElMessage.success('Time ble slettet.')
            } catch (error) {
                ElMessage.error('Feil ved sletting av time.')
            }
        },
        async deleteActiveHour() {
            try {
                await HTTP.delete('hours/' + this.activeHour.id, authHeader())
                this.activeHour = null
            } catch (error) {
                ElMessage.error('Feil ved stopping av timer.')
            }
        },
        async updateHour(hour) {
            if (hour.emp_email === undefined || hour.emp_email === null || hour.emp_email === "") {
                hour.emp_email = this.authStore.email
            }
            console.log("Updating hour: ", hour)
            try {
                await HTTP.put('hours/' + hour.id, hour, authHeader())
                ElMessage.success('Time ble oppdatert.')
                return true;
            } catch (error) {
                ElMessage.error('Feil ved oppdatering av time.')
                return false;
            }
        }
    }

})