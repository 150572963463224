import { createRouter, createWebHistory } from 'vue-router'
import { useAppStore } from "@/store/appStore";
import { useAuthStore } from "@/store/authStore";
import DashboardView from "@/views/DashboardView.vue";
import DashboardViewAdmin from "@/views/DashboardView/DashboardViewAdmin.vue";
import DashboardViewMain from "@/views/DashboardView/DashboardViewMain.vue";
import LoginView from "@/views/LoginView.vue";
import DashboardViewManager from "@/views/DashboardView/DashboardViewManager.vue";


// Gets the user if a JWT already exists in localStorage
const authenticate = async() => {
   const token = localStorage.getItem('token')
   if (token) {
      await useAuthStore().getMe()
   }
}

// Checks if the user is logged in. If not, redirect to Login-page
// Called every time before entering the Dashboard-page
const isAuthenticated = async(to, from, next) => {
   await authenticate()
   if (useAuthStore().isAuthenticated) {
      next()
   } else {
      next('/login')
   }
}

// Checks if the user is logged in. If so, redirect to Dashboard-page
// Unlike 'isAuthenticated()', this one gets called before entering the Login-page
const isAlreadyAuthenticated = async(to, from, next) => {
   await authenticate()
   if (useAuthStore().isAuthenticated) {
      next('/')
   } else {
      next()
   }
}

// Checks if the user is logged in and is an admin.
// If so, continue to Admin-page, otherwise redirect to Login-page.
// Gets called before entering the Admin-page.
const isAdmin = async(to, from, next) => {
   // No need to call 'authenticate()' because 'isAuthenticated()' will always be called before, in the parent route
   if (useAuthStore().isAdmin) {
      if (!useAppStore().activeMenuItem.includes('admin')) {
         useAppStore().activeMenuItem = 'admin-emp'
      }
      next()
   } else {
      next('/login')
   }
}

// Checks if the user is logged in and is a manager or admin.
// If so, continue to Manager-page, otherwise redirect to Login-page.
// Gets called before entering the Manager-page.
const isManager = async(to, from, next) => {
   // No need to call 'authenticate()' because 'isAuthenticated()' will always be called before, in the parent route
   if (useAuthStore().isManager || useAuthStore().isAdmin) {
      if (!useAppStore().activeMenuItem.includes('mng')) {
         useAppStore().activeMenuItem = 'mng-hours'
      }
      next()
   } else {
      next('/login')
   }
}

const routes = [
   {
      path: '/',
      name: 'Dashboard',
      component: DashboardView,
      beforeEnter: isAuthenticated,
      children: [
         { path: '/', component: DashboardViewMain },
         { path: '/admin', component: DashboardViewAdmin, beforeEnter: isAdmin },
         { path: '/manager', component: DashboardViewManager, beforeEnter: isManager },
      ],
   },
   {
      path: '/login',
      name: 'Login',
      component: LoginView,
      beforeEnter: isAlreadyAuthenticated,
   },
   {
      path: '/:catchAll(.*)',
      redirect: '/'
   },

]

const router = createRouter({
   history: createWebHistory(),
   routes
})

export default router