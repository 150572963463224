import { defineStore } from "pinia";
import { HTTP, authHeader } from "@/api";
import { ElMessage } from "element-plus";


export const useAuthStore = defineStore("auth", {
   state: () => ({
      employee: null,
   }),
   getters: {
      isAuthenticated: (state) => !!state.employee,
      name: (state) => state.isAuthenticated ? state.employee.name : "",
      email: (state) => state.isAuthenticated ? state.employee.email : "@",
      isAdmin: (state) => state.isAuthenticated ? state.employee.is_admin : false,
      managerFor: (state) => state.isAuthenticated ? state.employee.manager_for : [],
      isManager: (state) => state.managerFor.length > 0
   },
   actions: {
      async login(loginData) {
         try {
            const response = await HTTP.post('auth/login', loginData)
            const token = response.data.access_token
            localStorage.setItem('token', token)
            return true
         } catch (error) {
            if (error.response.status === 401) {
               ElMessage.error('Feil e-post eller passord.')
            } else {
               ElMessage.error('Ukjent feil ved pålogging.')
            }
            return false
         }
      },
      async getMe() {
         console.log("getme1")
         try {
            const response = await HTTP.get('auth/me', authHeader())
            this.employee = response.data
            console.log(this.employee)
            console.log("getme2 suc")
         } catch (error) {
            console.log("getme2 err")
            ElMessage.error('Kunne ikke hente bruker.')
         }
      },
      logOut() {
         this.employee = null
         localStorage.removeItem('token')
      },
      async changePw(passwordData) {
         try {
            await HTTP.put('auth/password/' + this.email, passwordData, authHeader())
            ElMessage.success('Passord ble oppdatert.')
            return true
         } catch (error) {
            if (error.response.status === 403) {
               ElMessage.error('Feil passord skrevet inn.')
            } else {
               ElMessage.error('Feil ved oppdatering av passord.')
            }
         }
      }
   }
})