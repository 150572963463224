<template>
   <el-container>
      <el-aside width="278px">
         <TheMenu />
      </el-aside>

      <el-main>
         <div id="dashboard-main-div">
            <!-- DashboardView's sub-router. Will display one of its children -->
            <router-view />
         </div>
      </el-main>
   </el-container>
</template>

<script>
import TheMenu from "@/components/DashboardView/TheMenu.vue";

export default {
   name: 'DashboardView',
   components: {
      TheMenu,
   },
   data() {
      return {
         loading: {table: true, projects: true, newHour: false, timer: true, departments: true, companies: true},
      }
   },
   provide() {
      return {
         loading: this.loading,
      }
   },
}
</script>

<style>
#dashboard-main-div {
   margin: 60px auto;
   width: 85%;
   text-align: center;
}
</style>
