<template>

  <!-- Table controls -->
  <el-row class="table-controls">
    <el-button :icon="plusIcon" @click="showProjectDialog = true">Nytt prosjekt</el-button>
    <el-button :icon="editIcon" :disabled="!selectedRow" @click="editProjectClicked">Rediger</el-button>
    <el-button :icon="deleteIcon" :disabled="!selectedRow" type="danger" @click="deleteProjectClicked">Slett
    </el-button>
  </el-row>

  <!-- The project table -->
  <el-table
      ref="tableRef"
      :data="projectStore.projectsGroupedListView"
      v-loading="loading.projectTable"
      highlight-current-row
      @row-click="rowClicked"
      border stripe>

    <el-table-column prop="name" label="Prosjekt" min-width="210px"/>
    <el-table-column label="Selskap" min-width="210px">
      <template #default="scope">
        {{
          departmentStore !== undefined && departmentStore.getDepartment(scope.row.dep_id) !== undefined ? departmentStore.getDepartment(scope.row.dep_id).name || "Loading" : "Loading"
        }}
      </template>
    </el-table-column>
    <el-table-column label="Betalt Lunsj" width="110px" align="center">
      <template #default="scope">
        <el-icon v-if="scope.row.has_paid_lunch"><Select/></el-icon>
      </template>
    </el-table-column>
  </el-table>

  <!-- New/edit employee form dialog -->
  <el-dialog v-model="showProjectDialog" :title="projectDialogTitle" width="40%" @close="employeeDialogClosed">

    <el-form ref="projectForm" :model="projectForm" label-position="left" label-width="90px"
             :rules="projectFormRules" status-icon>
      <el-form-item label="Avdeling" prop="name">

        <div class="flex flex-wrap">
          <el-dropdown @change="onDepartmentChange">
            <el-button type="primary">
              {{ selectedDepartmentName === null ? "Avdeling" || selectedDepartmentName : selectedDepartmentName }}
              <el-icon class="el-icon--right">
                <arrow-down/>
              </el-icon>
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item v-for="department in departmentStore.departments" :key="department.id"
                                  @click="onDepartmentChange" :value="department.id">
                  {{ department.name }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </el-form-item>
      <!--      <DropdownSelector :departments="departmentStore.departments"></DropdownSelector>-->
      <el-form-item label="Navn" prop="name">
        <el-input v-model="projectForm.name" placeholder="skriv et navn"/>
      </el-form-item>
      <el-form-item label="Betalt lunsj">
        <el-switch v-model="projectForm.has_paid_lunch"/>
      </el-form-item>
    </el-form>

    <!-- Dialog footer controls -->
    <template #footer>
      <el-button @click="showProjectDialog = false">Avbryt</el-button>
      <el-button v-if="this.projectEditingForm" type="primary" @click="projectSubmitClicked(true)">
        Oppdater prosjekt
      </el-button>
      <el-button v-else type="primary" @click="projectSubmitClicked(false)">Legg til prosjekt</el-button>
    </template>
  </el-dialog>

</template>

<script>
import {useProjectStore} from "@/store/projectStore";
import {Edit, Delete, Select, Plus, ArrowDown} from '@element-plus/icons-vue';
import {shallowRef} from "vue";
import {ElMessageBox} from "element-plus";
import {useDepartmentStore} from "@/store/departmentStore";


export default {
  name: "ManagerProjects",
  components: {
    ArrowDown,
    Select,
  },
  setup() {
    const projectStore = useProjectStore()
    const departmentStore = useDepartmentStore()
    return {projectStore, departmentStore}
  },
  data() {
    return {
      loading: {projectTable: false},
      projectForm: {},
      selectedRow: null,
      showProjectDialog: false,
      projectEditingForm: false,
      projectFormRules: {
        name: [{required: true, min: 2, message: 'Skriv inn et fullt navn.', trigger: 'blur'}],
      },
      editIcon: shallowRef(Edit),
      deleteIcon: shallowRef(Delete),
      plusIcon: shallowRef(Plus),
      ArrowDown: shallowRef(ArrowDown),
      selectedDepartmentId: null,
      selectedDepartmentName: null,
      selectedProjectId: null,
    }
  },
  computed: {
    // Dialog title
    projectDialogTitle() {
      return this.projectEditingForm ? 'Rediger prosjekt' : 'Nytt prosjekt'
    }
  },
  methods: {
    async loadTable() {
      await this.departmentStore.getAllManagerDepartments()
      this.loading.projectTable = true
      this.selectedRow = null
      await this.projectStore.getAllProjectsManager()
      this.loading.projectTable = false
    },
    onDepartmentChange(departmentElement) {
      this.selectedDepartmentId = departmentElement.target.value;
      this.selectedDepartmentName = this.departmentStore.getDepartment(this.selectedDepartmentId).name;
    },
    // Gets called when clicking a row. Selects or de-selects it
    rowClicked(row) {
      if (this.selectedRow === row) {  // if clicked second time (if already selected)
        this.selectedRow = null
        this.selectedProjectId = null
        this.selectedDepartmentId = null
        this.selectedDepartmentName = null
        this.$refs.tableRef.setCurrentRow(null)
      } else {  // if clicked first time
        this.selectedRow = row
        this.selectedProjectId = row.id
        this.selectedDepartmentId = row.dep_id
        this.selectedDepartmentName = this.departmentStore.getDepartment(this.selectedDepartmentId).name
      }
    },
    async projectSubmitClicked(isEditing) {
      await this.$refs.projectForm.validate((isValid) => {
        if (isValid) {
          this.addProject(isEditing)
          this.showProjectDialog = false
        }
        return isValid
      })
    },
    // Gets called when dialog is closed
    employeeDialogClosed() {
      // Dialog data gets removed before dialog is closed, so putting a small delay making it unnoticeable
      setTimeout(() => {
        this.projectForm = {}
        this.projectEditingForm = false
        this.$refs.projectForm.resetFields()
      }, 100)
    },
    async addProject(isEditing) {
      this.loading.projectTable = true
      const project = {
        dep_id: this.selectedDepartmentId,
        name: this.projectForm.name,
        has_paid_lunch: this.projectForm.has_paid_lunch || false,
      }
      console.log("Editing: ", isEditing)
      if (isEditing) {
        await this.projectStore.updateProject(project, this.selectedProjectId)
      } else {
        await this.projectStore.postProject(project)
      }

      await this.loadTable()
    },
    // Gets called when clicking on 'Edit employee'. Fills the dialog with data from the selected Employee
    editProjectClicked() {
      this.projectEditingForm = true
      this.showProjectDialog = true
      const project = this.selectedRow
      this.projectForm = {name: project.name, has_paid_lunch: project.has_paid_lunch}
    },
    async deleteProjectClicked() {
      ElMessageBox.confirm(
          'Er du sikker på at du vil slette prosjekt ' + this.selectedRow.name + '?',
          'Bekreft valg',
          {confirmButtonText: 'OK', cancelButtonText: 'Avbryt', type: 'warning'}
      ).then(async () => {
        this.loading.projectTable = true
        await this.projectStore.deleteProject(this.selectedRow)
        await this.loadTable()
      }).catch(() => {
      })
    },
    employeeResetPwClicked() {
      ElMessageBox.confirm(
          'Er du sikker på at du vil tilbakestille passordet til ' + this.projectForm.email + '?',
          'Bekreft valg',
          {confirmButtonText: 'OK', cancelButtonText: 'Avbryt', type: 'warning'}
      ).then(async () => {
        this.showProjectDialog = false
        await this.employeeStore.resetPwForEmployee(this.projectForm.email)
      }).catch(() => {
      })
    }
  },
  async mounted() {
    await this.loadTable()
  }
}
</script>

<style scoped>

</style>