import axios from "axios";
import router from "@/router";
import { Constants } from "@/constants";
import { useAuthStore } from "@/store/authStore";
import { ElMessage } from "element-plus";


// Creates an Axios-instance that will be used in app to connect to API
export const HTTP = axios.create({
    baseURL: Constants.BACKEND_URL
})

// Intercept every response, to log out user if not authenticated
HTTP.interceptors.response.use(undefined, error => {
   if (error) {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
         originalRequest._retry = true;
         useAuthStore().logOut()
         router.push('/login')
         router.go()  // Completely reloads page, to reset all Pinia stores
         if (error.response.data.detail === "Token expired") {
            ElMessage.error('Sesjonen er utgått. Logg inn på nytt.')
         }
      }
      return Promise.reject(error)
   }
})


// Function for attaching JWT token to header for outgoing requests
export function authHeader() {
   let token = localStorage.getItem('token')
   if (token) {
      return { headers: { Authorization: `Bearer ${token}` }}
   } else {
      return {}
   }
}



