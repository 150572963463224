<template>
  <el-collapse style="margin-bottom: 50px">
    <el-collapse-item>
      <template
          #title>
        <p style="font-size: 18px">Legg til time for ansatt</p>
      </template>
      <br/>
      <AddEmployeeHour with-buttons/>
    </el-collapse-item>
  </el-collapse>
  <ManagerHoursTable/>
</template>

<script>
import ManagerHoursTable from "@/components/DashboardView/DashboardViewManager/ManagerHoursTable.vue";
import AddEmployeeHour from "@/components/DashboardView/DashboardViewManager/AddEmployeeHour.vue";

export default {
  name: "ManagerHours",
  components: {
    AddEmployeeHour,
    ManagerHoursTable
  },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style scoped>

</style>