<template>
  <el-row justify="space-between" class="table-controls">

    <!-- View mode picker (department/project/employee) -->
    <el-radio-group v-model="viewModeRadio">
      <el-radio-button label="department">Selskap</el-radio-button>
      <el-radio-button label="project">Prosjekt</el-radio-button>
      <el-radio-button label="employee">Ansatt</el-radio-button>
    </el-radio-group>

    <!-- Date range picker -->
    <el-row>
      <el-button @click="tableRangeArrowsClicked(-1)" :icon="arrowLeft"/>
      <el-date-picker
          ref="rangePickerRef"
          v-model="tableRange"
          @change="tableRangeChanged"
          @visible-change="rangePickerClosed"
          :type="tableRangeType"
          :format="tableRangeFormat"
          style="width: 150px"/>
      <el-button @click="tableRangeArrowsClicked(1)" :icon="arrowRight"/>
    </el-row>

    <!-- Date range type picker (day/week/month) -->
    <el-radio-group v-model="tableRangeType" @change="tableRangeTypeChanged">
      <el-radio-button label="date">Dag</el-radio-button>
      <el-radio-button label="week">Uke</el-radio-button>
      <el-radio-button label="month">Måned</el-radio-button>
    </el-radio-group>
  </el-row>
  <el-row class="line one-px-border">
    <div class="container">

      <DepartmentReport v-if="viewModeRadio === 'department'" :start_range="start_range" :end_range="end_range"
                        :tableRangeType="tableRangeType"/>
      <ProjectReport v-else-if="viewModeRadio === 'project'" :start_range="start_range" :end_range="end_range"
                     :tableRangeType="tableRangeType"/>
      <EmployeeReport v-else-if="viewModeRadio === 'employee'" :start_range="start_range" :end_range="end_range"
                      :tableRangeType="tableRangeType"/>
    </div>
  </el-row>

  <ManagerHoursTableEmployee :tableRangeType="tableRangeType" :table-range="tableRange"/>


</template>
<script setup>
import {computed, ref} from 'vue';
// import { Constants } from '@/constants';
import {ArrowLeft, ArrowRight,} from '@element-plus/icons-vue';
import EmployeeReport from "@/components/DashboardView/DashboardViewManager/ManagerReports/EmployeeReport.vue";
import ProjectReport from "@/components/DashboardView/DashboardViewManager/ManagerReports/ProjectReport.vue";
import DepartmentReport from "@/components/DashboardView/DashboardViewManager/ManagerReports/DepartmentReport.vue";
import ManagerHoursTableEmployee from "@/components/DashboardView/DashboardViewManager/ManagerHoursTableEmployee.vue";

const arrowLeft = ref(ArrowLeft);
const arrowRight = ref(ArrowRight);
// const deleteIcon = ref(Delete);
// const editIcon = ref(Edit);

const tableRange = ref(new Date());
const tableRangeType = ref('month');
const tableRangeTypePrevious = ref('month');
const viewModeRadio = ref('department');
const rangePickerRef = ref(null);
// const editRowShow = ref(false);
// const editHour = ref({});


function tableRangeArrowsClicked(sign) {
  let newTimestamp = null
  switch (tableRangeType.value) {
    case 'date':
      newTimestamp = tableRange.value.setDate(tableRange.value.getDate() + (1 * sign))
      break
    case 'week':
      newTimestamp = tableRange.value.setDate(tableRange.value.getDate() + (7 * sign))
      break
    case 'month':
      newTimestamp = tableRange.value.setMonth(tableRange.value.getMonth() + (1 * sign))
      break
  }
  tableRange.value = new Date(newTimestamp)
  // TODO prevent user to spam arrows
  tableRangeChanged();
}

function tableRangeChanged() {
  if (tableRange.value == null) {
    tableRange.value = new Date()
    tableRange.value.setHours(0, 0, 0, 0)
  }

  // this.rangePickerSelectedNew = true
}

function rangePickerClosed(visible) {
  if (!visible) {
    tableRangeTypePrevious.value = tableRangeType.value;
  }
}

function tableRangeTypeChanged(newType) {
  if (tableRangeTypePrevious.value !== newType) {
    const newDate = new Date();
    switch (newType) {
      case 'day':
        newDate.setDate(1);
        break;
      case 'week':
        newDate.setDate(newDate.getDate() - newDate.getDay() + 1);
        break;
      case 'month':
        newDate.setDate(1);
        newDate.setMonth(newDate.getMonth() - 1);
        break;
      default:
        return;
    }
    tableRange.value = newDate;
  }
  tableRangeTypePrevious.value = newType;
}

const tableRangeFormat = computed(() => {
  switch (tableRangeType.value) {
    case 'week':
      return '[Uke] ww YYYY'
    case 'month':
      return 'MMM YYYY'
    default:
      return 'DD. MMM YYYY'
  }
})

const start_range = computed(() => {
  const start = calculateRange()[0]
  return start / 1000;
})

const end_range = computed(() => {
  const end = calculateRange()[1]
  return end / 1000;
})

const calculateRange = () => {
  let day = null, diff = null, start = null, end = null

  switch (tableRangeType.value) {
    case 'date':
      start = new Date(tableRange.value)
      end = new Date(start)
      end.setDate(start.getDate() + 1)
      break
    case 'week':  // Find date of monday in selected week + next week
      day = tableRange.value.getDay()
      diff = tableRange.value.getDate() - day + (day === 0 ? -6 : 1)
      start = new Date(tableRange.value)
      start.setDate(diff)
      end = new Date(start)
      end.setDate(start.getDate() + 7)
      break
    case 'month':  // Find first date in selected month + next month
      start = new Date(tableRange.value.getFullYear(), tableRange.value.getMonth(), 1)
      end = new Date(tableRange.value.getFullYear(), tableRange.value.getMonth() + 1, 1)
      break
    default:
      start = new Date(tableRange.value.getFullYear(), tableRange.value.getMonth(), 1)
      end = new Date(tableRange.value.getFullYear(), tableRange.value.getMonth() + 1, 1)
      break
  }
  // console.log("Start: ", start, "End: ", end)
  return [start.valueOf(), end.valueOf()]
}

</script>

<style scoped>
.one-px-border {
  border: 1px solid #848484; /* Adjust color as needed */
  border-radius: 5px;
  padding: 10px;
}


</style>