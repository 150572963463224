import { useAuthStore } from "@/store/authStore";
import { defineStore } from "pinia";
import { HTTP, authHeader } from "@/api";
import { ElMessage } from "element-plus";

// Store contains all the departments that the user is manager for, and
// all of their employees and projects. Used for filtering hours-table
export const useManagerDepartmentStore = defineStore("managerDepartments", {
   state: () => ({
      authStore: useAuthStore(),
      departments: [],
   }),
   getters: {
   },
   actions: {
      async getDepartmentsAsManager() {
         try {
            const response = await HTTP.get('departments/manager', authHeader())
            this.departments = response.data
         } catch (error) {
            this.departments = []
            console.log(error)
            ElMessage.error('Feil ved innlasting av selskap.')
         }
      },
   }

})